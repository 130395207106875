@charset "UTF-8";

.button {
  @include button-base;
  background-color: $base-color;
  border: 1px solid $base-color;
  border-radius: $button-border-radius;
  color: #fff;
  transition: all 0.25s ease;
  &:hover {
    opacity: 0.8;
    color: #fff;
    text-decoration: none;
  }

  @include button-color;

  // アウトライン
  &.outline {
    background-color: #fff;
    border: $button-border solid $base-color;
    color: $base-color;
    text-shadow: none;

    &:hover {
      background-color: lighten($base-color,40%);
    }

    @include button-color-outline;

    &.ghost {
      background-color: transparent;
      border: $button-border solid rgba(#fff,0.7);
      color: rgba(#fff,0.7);
      &:hover {
        background-color: rgba(#000, 0.3);
      }
    }
  }

  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  // 採用ページ
  &.recruit{
    background:  $five-color;
    border-top: solid 1px $five-color;
    border-bottom: solid 1px darken($five-color, 30%);
    border-right: solid 1px $five-color;
    border-left: solid 1px $five-color;
    color: $base-font-color;
  }

  // アイコン付きのとき
  & span{
    display: inline-block;
  }

  //インデックスページのボタン
  &.annai{
    background: $cs-green-color;
    border-top: 1px solid $cs-green-color;
    border-left: 1px solid $cs-green-color;
    border-right: 1px solid $cs-green-color;
  }
  &.gairai{
    background: $cs-orange-color;
    border-top: 1px solid $cs-orange-color;
    border-left: 1px solid $cs-orange-color;
    border-right: 1px solid $cs-orange-color;
  }
  &.nyuin{
    background: $cs-red-color;
    border-top: 1px solid $cs-red-color;
    border-left: 1px solid $cs-red-color;
    border-right: 1px solid $cs-red-color;
  }
  &.bumon{
    background: $cs-blue-color;
    border-top: 1px solid $cs-blue-color;
    border-left: 1px solid $cs-blue-color;
    border-right: 1px solid $cs-blue-color;
  }
  &.saiyo{
    background: $cs-purple-color;
    border-top: 1px solid $cs-purple-color;
    border-left: 1px solid $cs-purple-color;
    border-right: 1px solid $cs-purple-color;
  }
  &.toiawase{
    background: $cs-pink-color;
    border-top: 1px solid $cs-pink-color;
    border-left: 1px solid $cs-pink-color;
    border-right: 1px solid $cs-pink-color;
  }

  &.disable{
    background: #ccc;
    border: solid 1px #ccc;
    color: #595959;
    &:hover{
      opacity: 1;
    }
  }
  
}

form button.button-block {
  display: block;
  width: 100%;
  @include media(lg) {
    margin: 2rem auto;
    width: 25%;
  }
}

.btn{
  @include button-base;
  background-color: $base-color;
  border: solid 1px $base-color;
  color: #fff;
  border-radius: $button-border-radius;
  span{
    &::after{
      margin-left: 0.5em;
      @include icon-default;
    }
    &.left{
      &::after{
        content:'';
      }
      &::before{
        margin-right: 0.5em;
        @include icon-default;
      }
    }
    
    @include icon-type;
  }
  &:hover{
    color: #fff;
  }
  &.expanded{
    width: 100%;
  }
  &.outline {
    background-color: #fff;
    border: $button-border solid $base-color;
    color: $base-color;
    text-shadow: none;
    &:hover {
      background-color: lighten($base-color,40%);
    }
    @include button-color-outline;
  }
}