@charset "UTF-8";

footer {
  flex: none;
  min-width: 320px;
  padding-top: map($footer-padding-top, sm);
  width: 100%;
  z-index: map($z-index, footer);
  overflow: hidden;
  @include media(md) {
    padding-top: map($footer-padding-top, md);
  }
  @include media(lg) {
    padding-top: 0;
  }
  .container{
    min-width: 100%;
  }
}

.footer-wrap {
  background: $base-color;
  @include media(lg) {
  display: none;
  }
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;
  color: #fff;
  .container{
    width: 100%;
  }
  .footer-logo {
    color: $gray-dark;
    font-family: Helvetica, Arial Black, sans-serif;
    font-weight: 800;
    font-size: 1.6rem;
    @include media(lg) {
      font-size: 2.0rem;
    }
  }
}

.copyright {
  background-color: $base-color;
  font-family: $latin;
  font-weight: 500;
  color: #fff;
  display: block;
  font-size: 1em;
  padding: .6em 0;
  text-align: center;
  width: 100%;
  letter-spacing: 0.1em;
}