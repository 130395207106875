@charset "UTF-8";

// icoのベース設定
@mixin ico{
  position: relative;
  padding-left: 1.4em;
  &::before{
    content: "";
    width: 1em;
    height: 1em;
    display: inline-block;
    //margin-right: 0.25em;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    //margin-top: 0.1em;
  }
}

@mixin ico-right{
  position: relative;
  padding-right: 1.4em;
  padding-left: 0;
  &::before{
    content: "";
    width: 1em;
    height: 1em;
    display: inline-block;
    //margin-right: 0.25em;
    position: absolute;
    top: 50%;
    left : auto;
    right: 0;
    transform: translateY(-50%);
    //margin-top: 0.1em;
  }
}

//電話アイコン
.ico-phone-wh{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_phone_wh.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-phone-bl{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_phone_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//マップピンアイコン
.ico-pin-wh{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_pin_wh.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-pin-bl{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_pin_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//FAXアイコン
.ico-fax-wh{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_fax_wh.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-fax-bl{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_fax_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//mailアイコン
.ico-mail-wh{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_mail_wh.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-mail-bl{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_mail_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//矢印（細い）アイコン
.ico-arrow-wh{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_arrow_wh.svg" );
    background-size: contain;
    background-repeat: no-repeat;
    width: 0.75em;
    height: 0.75em;
  }
}
.ico-arrow-bl{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_arrow_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
    width: 0.75em;
    height: 0.75em;
  }
}

//書類
.ico-doc-bk{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_doc_bk.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//車
.ico-car-bk{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_car_bk.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-car-bl{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_car_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//バス
.ico-bus-bk{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_bus_bk.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-bus-bl{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_bus_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//電車
.ico-train-bk{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_train_bk.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-train-bl{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_train_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//インフォーメーションアイコン
.ico-info-rd{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_info_rd.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//PDFアイコン
.ico-pdf-rd{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_pdf_rd.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-pdf-bl{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_pdf_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-pdf-rd-right{
  @include ico-right;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_pdf_rd.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-pdf-bl-right{
  @include ico-right;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_pdf_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//ダウンロードアイコン
.ico-dl-wh{
  @include ico;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_dl_wh.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.ico-dl-wh-right{
  @include ico-right;
  &::before{
    background: url( $root-path + "assets/img/icon/icon_dl_wh.svg" );
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.icon{
  span{
    &::after{
      margin-left: 0.25em;
      @include icon-default;
    }
    &.left{
      &::after{
        content:'';
      }
      &::before{
        margin-right: 0.25em;
        @include icon-default;
      }
    }
    @include icon-type;
  }
}