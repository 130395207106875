@charset "UTF-8";

.block {
	border: 1px solid $base-color;
  &.center {
    text-align: center;
  }
  &.height {
    height: 100%;
  }
  @include border-color;
}

.block-header {
  background: $base-color;
  color: #fff;
  font-size: 1.25em;
  font-weight: normal;
  padding: .25em;
  text-align: center;
  @include block-header-background;
}

.block-body {
  color: inherit;
  padding: 1.5em;
  & .grid {
    margin: (- grid-margin(sm));
    @include media(md) {
      margin: 0 (- grid-margin(md));
    }
    @include media(lg) {
      margin: 0 (- grid-margin(lg));
    }
  }
}
