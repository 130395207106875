@charset "UTF-8";

.ribbon {
  background-color: $base-color;
  border-radius: 0px;
  color: #fff;
  display: inline-block;
  font-size: inherit;
  text-align: center;
  margin: .5em 0;
  padding: .3em 1em;
  position: relative;

  &.round {
    border-radius: 2em;
  }

  &.deco {
    height: map($base-font-size, sm) * 2.4 + px;
    line-height: map($base-font-size, sm) * 2.4 + px;
    padding: 0 map($base-font-size, sm)+(map($base-font-size, sm) * 2.4 * .3)+px 0 map($base-font-size, sm)+px;
    &::after {
      content: '';
      position: absolute;
      width: 0px;
      height: 0px;
      z-index: 1;
      top: 0;
      right: 0;
      border-width: (map($base-font-size, sm) * 2.4 / 2)+px (map($base-font-size, sm) * 2.4 * .3)+px (map($base-font-size, sm) * 2.4 / 2)+px 0px;
      border-color: transparent #fff transparent transparent;
      border-style: solid;
    }
    @include media(lg) {
      height: map($base-font-size, lg) * 2.4 + px;
      line-height: map($base-font-size, lg) * 2.4 + px;
      padding: 0 map($base-font-size, lg)+(map($base-font-size, lg) * 2.4 * .3)+px 0 map($base-font-size, lg)+px;
      &::after {
        border-width: (map($base-font-size, lg) * 2.4 / 2)+px (map($base-font-size, lg) * 2.4 * .3)+px (map($base-font-size, lg) * 2.4 / 2)+px 0px;
      }
    }
  }
  @include default-background-color; // background-color

  &.outline {
    background: #fff;
    border: 1px solid $base-color;
    color: $base-color;
    @include border-font-color; // border & background color
  }
}
