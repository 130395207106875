@charset "UTF-8";

// ---------------------------------------------------------------- box-sizing
* {
  box-sizing: border-box;
}

// ---------------------------------------------------------------- html
html {
  font-size: calc(1em * 0.625); // 16px -> 10px
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

// ---------------------------------------------------------------- body
body {
  background: $background-body;
  font-family: $font-family;
  font-size: map($base-font-size, sm, px);
  line-height: map($base-line-height, sm);
  letter-spacing: 0.0425em;
  height: 100%;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pkna" 1;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  @include media(md) {
    font-size: map($base-font-size, md, px);
    line-height: map($base-line-height, md);
  }
  @include media(lg) {
    font-size: map($base-font-size, lg, px);
    line-height: map($base-line-height, lg);
  }
  // フォントレンダリング設定
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
// IE11 用 hack
_:-ms-lang(x), body { display: flex; }

// ---------------------------------------------------------------- アンカーリンク
a {
  color: $link-color;
  text-decoration: $link-decoration;
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

// ---------------------------------------------------------------- img
img {
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

// ---------------------------------------------------------------- hr
hr {
  max-width: width(lg);
  height: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: $hr-border;
  border-left: 0;
  margin: map($hr-margin, sm) auto;
  clear: both;
  @include media(md) {
    margin: map($hr-margin, md) auto;
  }
  @include media(lg) {
    margin: map($hr-margin, lg) auto;
  }
}


