@charset "UTF-8";

nav{
  @include media(lg) {
    padding: 3.2rem 0;
    //margin: 2.4rem 0;
  }
}

//メニューボタンの位置・サイズ
.open-wrap{
  @include media(lg) {
    display: none;
  }
}
.open{
  display: block;
  position: fixed;
  z-index: 20;
  right: 0;
  bottom: 7rem;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  background: rgba(#333, 0.8);
  @include media(lg) {
    display: none;
  }
  .open-inner{
    position: relative;
    width: 100%;
    height: 100%;
    & > span::after{
      content:"MENU";
      font-size: 1.3rem;
      position: absolute;
      padding-top: 0.2em;
      bottom: 3px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-family: $latin;
      font-weight: 500;
      line-height: 1;
    }
  }
  //バーガーアイコン他
  .burger{
    position: relative;
    margin: 0 auto;
    width: 26px;
    span{
      height: 2px;
      width: 26px;
      background: #fff;
      display: block;
      transition: 0.25s all ease 0s;
      position: absolute;
      top: 10px;
      &:nth-child(2){
        top: 18px;
      }
      &:last-child{
        top: 26px;
      }
    }
  }
}

.close{
  cursor: pointer;
  border: solid 2px #333;
  display: block;
  padding: 1rem;
  width: 20rem;
  margin: 2rem auto;
  font-size: 2rem;
  line-height: 1.5;
  @include media(lg) {
    display: none;
  }
  .close-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    & > span{
      &::after{
        content:"CLOSE";
        color: #333;
        margin-left: 0.5em;
        font-family: $latin;
        font-weight: 500;
      }
    }
  }
  //バーガーアイコン他
  .burger{
    position: relative;
    width: 2rem;
    height: 2rem;
    span{
      height: 2px;
      width: 26px;
      background: #333;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      &:first-child{
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:nth-child(2){
        transform: translate(-50%, -50%) scale(0, 0);
      }
      &:last-child{
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

.child-trigger{
  display: block;
  right: 0;
  top: 0;
  height: 100%;
  position: absolute;
  cursor: pointer;
  transition: color 0.2s;
  &.__right{
    width: 4.5rem;
    height: 4.5rem;
    background: rgba(#000, 0.2);
    transition: background-color 0.2s;
    top: 50%;
    transform: translateY(-50%);
    @include media(lg) {
      width: 4.8rem;
      height: 4.8rem;
    }
    &::after{
      width: 1.2rem;
      height: 1.2rem;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
    }
    &.active::after{
      transform: translate(-50%, -50%) rotate(-90deg);
    }
    @media (hover: hover) {
      &:hover{
        background: rgba(#000, 0.1);
      }
    }
  }
  &.__self{
    width: 100%;
    &::after{
      transform: translateY(-50%) rotate(90deg);
      background-size: .8rem;
      background-position: center;
      width: 1.8rem;
      height: 1.8rem;
      border: solid 1px #fff;
      border-radius: 50%;
      right: $layout-padding;
    }
    &.active::after{
      transform: translateY(-50%) rotate(-90deg);
    }
  }
  &::after{
    position: absolute;
    display: block;
    content: '';
    background: url( $img-path + 'icon/icon_arrow_wh.svg' )no-repeat;
    background-size: cover;
    top: 50%;
    transition: transform 0.2s;
  }
}

.sub-child-item{
  display: none;
  background: rgba(#333, 0.2);
}

.child-column{
  display: none;

  .child-group{
  }

  &#cat-1 {
    .child-group{
      background: $cs-green-color;
    }
  }
  &#cat-2 {
    .child-group{
      background: $cs-orange-color;
    }
  }
  &#cat-3 {
    .child-group{
      background: $cs-red-color;
    }
  }
  &#cat-4 {
    .child-group{
      background: $cs-pink-color;
    }
  }
  &#cat-5 {
    .child-group{
      background: $cs-blue-color;
    }
  }
  &#cat-6 {
      .child-group{
      background: $cs-purple-color;
    }
  }
  
  &#cat-7 {
    .child-group{
      background: $cs-emerald-color;
    }
  }
}

//PC時のメニュー
.nav-wrap{
  display: none;
  @include media(lg) {
    display: block;
    margin-top: -1rem;
  }
  .nav-group{
    .header-container{
      margin-top: 1rem;
      &:first-of-type .parent{
        margin-top: 0;
      }
    }
    .parent{
      display: flex;
      margin: 0 -0.5rem;
      .half-box{
        width: 15rem;
        height: 14rem;
        padding: 0 0.5rem;
        cursor: pointer;
        transition: 0.25s all ease 0s;
        .toggle{
          background: #fff;
          color: #595959;
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
          transition: 0.25s all ease 0s;
          span{
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            text-align: center;
            font-family: $jp;
            font-size: 0.9em;
            font-weight: 500;
            letter-spacing: 0;
            @include media(lg) {
              font-size: 1.125em;
            }
            &::before{
              content: "";
              width: 6rem;
              height: 6rem;
              display: block;
              margin: 0 auto 0.25em auto;
            }
          }
        }
        //以下各設定
        &.annai .toggle{
          color: $cs-green-color;
          &.active{
            background: $cs-green-color;
            color: #fff;
          }
          span::before{
            background: url( $img-path + 'icon/icon_annai.svg' )no-repeat;
            background-size: cover;
          }
        }
        &.gairai .toggle{
          color: $cs-orange-color;
          &.active{
            background: $cs-orange-color;
            color: #fff;
          }
          span::before{
            background: url( $img-path + 'icon/icon_gairai.svg' )no-repeat;
            background-size: cover;
          }
        }
        &.nyuin .toggle{
          color: $cs-red-color;
          &.active{
            background: $cs-red-color;
            color: #fff;
          }
          span::before{
            background: url( $img-path + 'icon/icon_nyuin.svg' )no-repeat;
            background-size: cover;
          }
        }
        &.bumon .toggle{
          color: $cs-blue-color;
          &.active{
            background: $cs-blue-color;
            color: #fff;
          }
          span::before{
            background: url( $img-path + 'icon/icon_bumon.svg' )no-repeat;
            background-size: cover;
          }
        }
        &.saiyo .toggle{
          color: $cs-purple-color;
          &.active{
            background: $cs-purple-color;
            color: #fff;
          }
          span::before{
            background: url( $img-path + 'icon/icon_saiyo.svg' )no-repeat;
            background-size: cover;
          }
        }
        &.kenshin .toggle{
          color: $cs-pink-color;
          &.active{
            background: $cs-pink-color;
            color: #fff;
          }
          span::before{
            background: url( $img-path + 'icon/icon_kenshin.svg' )no-repeat;
            background-size: cover;
          }
        }
      }

      .full-box{
        width: 100%;
        height: 13rem;
        padding: 0 0.5rem;
        cursor: pointer;
        transition: 0.25s all ease 0s;
        .toggle{
          background: #fff;
          color: #595959;
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
          transition: 0.25s all ease 0s;
          span{
            display: flex;
            align-items: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            text-align: center;
            font-family: $jp;
            font-size: 0.9em;
            font-weight: 500;
            letter-spacing: 0;
            //flex-direction: row;
            flex-direction: column;
            @include media(lg) {
              font-size: 1.125em;
            }
            &::before{
              content: "";
              width: 6rem;
              height: 6rem;
              display: block;
              //margin-right: 0.5em;
              margin: 0 auto 0.25em auto;
            }
          }
        }
        //以下各設定
        &.reha .toggle{
          color: $cs-emerald-color;
          &.active{
            background: $cs-emerald-color;
            color: #fff;
          }
          span{
            &::before{
              background: url( $img-path + 'icon/icon_reha.svg' )no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }
    //子どもたち
    .child{
      .link-parent{
        position: relative;
        color: #fff;
        padding: 1.2rem 5rem;
        display: block;
        font-size: 1em;
        transition: 0.25s all ease 0s;
        line-height: 1.5;
        min-height: 4.8rem;
        display: flex;
        align-items: center;
        &::before{
          content: "";
          position: absolute;
          background: url( $img-path + 'icon/icon_arrow_wh.svg' )no-repeat;
          width: 1.2rem;
          height: 1.2rem;
          left: $layout-padding;
          background-size: cover;
          top: 50%;
          transform: translateY(-50%);
        }
        @media (hover: hover) {
          &:hover{
            background: rgba(#333, 0.2);
          }
        }
      }
      .link-child{
        position: relative;
        color: #fff;
        padding: 1.2rem 5rem;
        display: block;
        font-size: 0.875em;
        transition: 0.25s all ease 0s;
        line-height: 1.5;
        min-height: 4.8rem;
        display: flex;
        align-items: center;
        @media (hover: hover) {
          &:hover{
            background: rgba(#333, 0.2);
          }
        }
      }
    }
  }

  .child-wrapper{
    & > .link-parent,
    & > .link-child{
      cursor: default;
      padding: 1.2rem 6rem 1.2rem 5rem !important;
      @media (hover: hover) {
        &:hover{
          background: transparent !important;
        }
      }
    }
  }

}

.active.s-nav-wrap{
  transform: scale(1, 1);
}

//SP時のメニュー
.s-nav-wrap{
  transform: scale(0, 0);
  transition: 0.25s all ease 0s;
  position: fixed;
  background: rgba(#fff, 1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  padding: 2.8rem 0;
  overflow-y: scroll;
  z-index: 999;
  @include media(lg) {
    display: none;
  }
  .parent{
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    & > li > .toggle{
      font-weight: bold;
      padding: 2rem 5rem;
      display: block;
      line-height: 1.5;
      span{
        position: relative;
        padding-left: 4.4rem;
        font-size: 1.6rem;
        &::before{
          content: "";
          width: 3.2rem;
          height: 3.2rem;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .link-parent{
      display: block;
      padding: 1.2rem 7rem;
      color: #fff;
      font-size: 1.4rem;
      position: relative;
      line-height: 1.5;
      min-height: 4.5rem;
      display: flex;
      align-items: center;
      &::before{
        content: "";
        position: absolute;
        background: url( $img-path + 'icon/icon_arrow_wh.svg' )no-repeat;
        width: 0.75em;
        height: 0.75em;
        left: 5rem;
        background-size: cover;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .link-child{
      display: block;
      padding: 1.2rem 7rem;
      color: #fff;
      font-size: 1.3rem;
      position: relative;
      min-height: 4.5rem;
      display: flex;
      align-items: center;
    }
    .toggle{
      cursor: pointer;
    }
    li{
      //以下各設定
      &.annai{
        & > .toggle{
          color: $cs-green-color;
          span::before{
            background-image: url( $img-path + 'icon/icon_annai.svg' );
          }
        }
      }
      &.gairai{
        & > .toggle{
          color: $cs-orange-color;
          span::before{
            background-image: url( $img-path + 'icon/icon_gairai.svg' );
          }
        }
      }
      &.nyuin{
        & > .toggle{
          color: $cs-red-color;
          span::before{
            background-image: url( $img-path + 'icon/icon_nyuin.svg' );
          }
        }
      }
      &.bumon{
        & > .toggle{
          color: $cs-blue-color;
          span::before{
            background-image: url( $img-path + 'icon/icon_bumon.svg' );
          }
        }
      }
      &.saiyo{
        & > .toggle{
          color: $cs-purple-color;
          span::before{
            background-image: url( $img-path + 'icon/icon_saiyo.svg' );
          }
        }
      }
      &.kenshin{
        & > .toggle{
          color: $cs-pink-color;
          span::before{
            background-image: url( $img-path + 'icon/icon_kenshin.svg' );
          }
        }
      }

      &.reha{
        & > .toggle{
          color: $cs-emerald-color;
          span::before{
            background-image: url( $img-path + 'icon/icon_reha.svg' );
          }
        }
      }

      a{
        color: #333;
      }
    }
  }
  .child-wrapper{
    & > .link-parent,
    & > .link-child{
      cursor: default;
      padding: 1.2rem 6rem 1.2rem 7rem !important;
    }
  }
}

.social-wrpper{
  display: none;
  @include media (lg) {
    display: flex;
    margin: -0.5em;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    .social-icon{
      width: 3.5em;
      height: 3.5em;
      display: block;
      padding: 0.5em;
      img{
        width: 100%;
      }
    }
  }
}

.s-social-wrpper{
  display: flex;
  margin: -0.5em;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  .social-icon{
    width: 3.5em;
    height: 3.5em;
    display: block;
    padding: 0.5em;
    img{
      width: 100%;
    }
  }
  @include media (lg) {
    display: none;
  }
}

