@charset "UTF-8";

// Pagetop

.pagetop {
  display: none;
  position: fixed;
  bottom: 1rem;
  right: 0;
  z-index: 998;

  @include media(lg) {
    bottom: 6rem;
    right: 0;
  }

  & a {
    display: block;
    background-color: rgba(#333, 0.8);
    text-align: center;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    width: 50px;
    height: 50px;
    padding: 0.4rem;
    @include media(md) {
      width: 50px;
      height: 50px;
    }
    @include media(lg) {
      width: 60px;
      height: 60px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      border-right: 2px solid #fff;
      border-top: 2px solid #fff;
      transform: rotate(-45deg);
      margin-top: 1.2em;
      transition: transform .3s ease-out;
      @include media(lg) {
        width: 24px;
        height: 24px;
        margin-top: 1.4em;
      }
    }
  }
}
