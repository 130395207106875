@charset "UTF-8";

// SUB PAGE

.sub{
  &.row::before{
    content: "";
    background: url( $img-path + 'gaikan-sp.jpg')no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -999;
    opacity: 0.75;
    @include media(lg) {
      background: url( $img-path + 'gaikan.jpg')no-repeat;
      background-size: cover;
    }
  }
  .main-wrap{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
  .main-column{
    flex: 1 0 auto;
    min-height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .container{
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      min-height: 0%;
      @include media(lg) {
        width: 100%;
      }
    }
  }
  .msg-wrap{
    position: relative;
    height: 10em;
    z-index: 1;
    @include media(md) {
      height: 13em;
    }
    @include media(lg) {
      height: 15em;
    }
  }
  .page-category{
    letter-spacing: 0.1em;
    font-family: $latin;
    font-weight: 500;
    font-size: 1.5em;
    padding: 0.25em 0.5em;
    color: #fff;
    display: inline-block;
    margin: 0.5em 0 0 0.5em;
    align-self: flex-start;
    @include media(lg) {
      top: 0;
      right: 0;
      text-indent: 0.5em;
      position: absolute;
      margin: 0;
      align-self: auto;
    }
  }
  .section-wrap{
    background: #fff;
    padding: 2em;
    flex: 1 0 auto;
    min-height: 0%;
    @include media(md) {
      padding: 3em;
    }
    @include media(lg) {
      padding: 3.5em;
    }
    @include media(xl){
      padding: 4em;
    }
  }
}

//page category
#annai{
  .page-category{
    background: $cs-green-color;
  }
}
#gairai{
  .page-category{
    background: $cs-orange-color;
  }
}
#nyuin{
  .page-category{
    background: $cs-red-color;
  }
}
#bumon{
  .page-category{
    background: $cs-blue-color;
  }
}
#saiyo{
  .page-category{
    background: $cs-purple-color;
  }
}
#kenshin{
  .page-category{
    background: $cs-pink-color;
  }
}
#reha{
  .page-category{
    background: $cs-emerald-color;
  }
}

.flow{
  > li{
    border: solid 4px #e8e4d4;
    padding: 1.5em;
    background: #f5f4ed;
    position: relative;
    text-align: center;
    & + li{
      margin-top: 3.2em;
      &::before{
        content: "";
        background: url('/assets/img/icon/icon_flow_bl.svg')no-repeat;
        width: 2em;
        height: 2em;
        display: inline-block;
        position: absolute;
        top: -2.8em;
        background-size: cover;
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }
  .flow-head{
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 0.5em;
    & .flow-head-att{
      font-size: 0.75em;
      font-weight: normal;
    }
  }
}

.bg-box{
  background: #f5f4ed;
  padding: 1em 1.5em;
  //border: solid 4px #e8e4d4;
}
.bg-box-bl{
  background: rgba($base-color, 0.1);
  padding: 1em 1.5em;
}
.line-box{
  padding: 1em 1.5em;
  border: solid 2px rgba($base-color,1);
}

.map{
  height: 360px;
  @include media(md) {
    height: 600px;
  }
}

.important{
  color: $red-color;
  border: solid 1px $red-color;
  background: rgba($red-color, 0.125);
  font-weight: bold;
  font-size: 1.25em;
  padding: 0.5em;
  text-align: center;
}

.img-banner-wrapper{
  display: block;
  border-radius: 0.75em;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.45);
  transition: box-shadow 0.2s;
  &::before{
    content: '';
    width :100%;
    height: 100%;
    display: block;
    background-color: rgba(#000, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    //transition: background-color 0.2s;
    transition: transform 0.2s;
  }
  // &::after{
  //   content: '';
  //   width :100%;
  //   height: 50%;
  //   display: block;
  //   background-color: rgba(#000, 0.3);
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  //   z-index: 1;
  //   transition: transform 0.2s;
  // }
  .img-banner-text{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    z-index: 2;
    color: #fff;
    font-weight: bold;
    padding: 0.5em;
    text-align: center;
    font-size: 1.125em;
    line-height: 1.5;
    text-shadow: 0px 0px 4px rgba(0,0,0,1);
  }
  &:hover{
    box-shadow: none;
    &::before{
      // background-color: rgba(#000, 0.2);
      // position: absolute;
      transform: translateY(100%);
    }
    // &::after{
    //   transform: translateY(100%);
    // }
  }
}

// タイムテーブル
.timeline-wrapper{
  padding: 2em 1em;
  background: #fff;
  box-shadow: 0px 0px 16px -6px rgba(0,0,0,0.5);
  border-radius: 1em;
  @include media(md) {
    padding: 3em 2em;
  }
  .timeline-item {
    position: relative;
    display: flex;
    //gap: 2em;
    &:last-child {
      .timeline-content {
        &:before {
          display: none;
        }
      }
    }
    .timeline-content{
      flex: 1;
      position: relative;
      order: 1;
      padding-left: 2em;
      &:before {
        content: "";
        position: absolute;
        left: -1px;
        top: 0.375em;
        height: 100%;
        width: 2px;
        background-color: $base-color;
      }
      &:after {
        content: "";
        position: absolute;
        left: -10px;
        top: 0.375em;
        width: 20px;
        height: 20px;
        background-color: white;
        z-index: 1;
        border: 2px solid $base-color;
        border-radius: 50%;
      }
    }
    &:not(:last-child) {
      .timeline-content{
        padding-bottom: 2em;
      }
      time {
        padding-bottom: 1em;
      }
    }
    .timeline-title {
      color: $base-color;
      font-weight: bold;
      font-size: 1.25em;
      padding-top: 0;
      margin-top: 0;
      margin-bottom: 0.5em;
      line-height: 1.8;
      @include media(md) {
        line-height: 1.6;
      }
    }
    .timeline-spacer {
      margin-top: 1em;
    }
    .timeline-desc {
      color: #333;
    }
    time {
      text-align: end;
      flex: 0 0 5em;
      min-width: 0;
      overflow-wrap: break-word;
      font-weight: bold;
      font-size: 1.25em;
      margin-right: 1.8em;
      line-height: 1.8;
      display: flex;
      align-self: flex-start;
      align-items: flex-start;
      justify-content: flex-end;
      color: $gray;
      @include media(md) {
        line-height: 1.6;
      }
      &::before{
        content: "\f017";
        font-family: FontAwesome;
        display: inline-block;
        margin-right: 0.25em;
        font-size: 1.125em;
        font-weight: normal;
        line-height: 1.8;
        @include media(md) {
          line-height: 1.6;
        }
      }
    }
  }
}

.btn-seminar{
  background: #315eab;
  background: linear-gradient( #315eab 0%, #6b91d3 50%, #315eab 100%);
  display: inline-block;
  color: #fff;
  font-weight: bold;
  font-size: 1.25em;
  line-height: 1.2;
  padding: 0.5em 2em;
  text-align: center;
  border-radius: 2em;
  text-shadow: 0px 1px 0px rgba(0,0,0,1);
  box-shadow: 0px 0px 0.25em 0px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s;
  .accent{
    color: #ddb847;
  }
  &:hover{
    color: #fff;
    opacity: 0.8;
  }
}

.modal-wrapper{
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  transition: visibility 0.2s, opacity 0.2s, background 0.2s;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  &.active{
    visibility: visible;
    opacity: 1;
    background: rgba($black-color, 0.9);
  }
  .modal-inner{
    width: 100%;
    height: calc(100% + 1px);
    padding: 4em 2em 2em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(lg) {
      padding: 4em;
    }
  }
  .modal-container{
    background-color: #fff;
    padding: 4em 2em;
    width: 100%;
    max-height: 100%;
    position: relative;
    @include media(lg) {
      width: 840px;
      padding: 4em;
    }
  }
  .close-btn{
    width: 2em;
    height: 3em;
    position: absolute;
    display: block;
    cursor: pointer;
    transition: background 0.2s;
    left: 0;
    top: -3em;
    &::before{ 
      content: '';
      width: 1.5em;
      height: 2px;
      display: block;
      background: $white-color;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after{
      content: '';
      width: 1.5em;
      height: 2px;
      display: block;
      background: $white-color;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  &.modal-video{
    .modal-container{
      background-color: transparent;
      padding: 0;
      @include media(lg) {
        padding: 0;
      }
    }
  }
}

.js-modal-trigger{
  cursor: pointer;
}

.loading-wrapper {
  background-color: $base-color;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 0.2s, visibility 0.2s;
  z-index: 999;
  &.disabled {
    opacity: 0;
    visibility: hidden;
  }
}

.loading-container {
  margin: 0 auto;
  width: 90%;
}

.loading {
  animation: rotate-loading 1.5s linear 0s infinite normal;
  border-radius: 100%;
  border: 2px solid transparent;
  border-color: transparent $white-color transparent $white-color;
  margin: 0 auto;
  position: relative;
  width: 100px;
  height: 100px;
  transform-origin: 50% 50%;
}

.loading-text {
  animation: loading-text-opacity 2s linear 0s infinite normal;
  color: $white-color;
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
}

@keyframes rotate-loading {
  0%  {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-text-opacity {
  0%  {
    opacity: 1
  }
  20% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
