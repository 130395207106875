@charset "UTF-8";

// ---------------------------------------------------------------- breakpoint（指定：px）

$breakpoints: (
  sm: 640,
  md: 768,
  lg: 1200,
  xl: 1400
  ) !default;

// ---------------------------------------------------------------- ルートパス

$root-path: "/";

// ---------------------------------------------------------------- 画像パス

$img-path: $root-path + "assets/img/";

// ---------------------------------------------------------------- layout

// サイドカラムの横幅
$side-width: 350px !default;

// サイドカラムのpadding
$layout-padding: 3rem !default;

// メインレイアウトのカラム数（ 1 = 1カラム : 2 = 2カラム : 3 = 3カラム ）
$main-layout-column: 1 !default;

// 2カラム時にサイドカラムをどちらに配置するか。使用する方に true
$side-right-flag: true !default; // 右カラム
$side-left-flag:  true !default; // 左カラム

// メインカラムの横幅（指定：px）
$width-main-column: 1224 !default;

// サイドカラムの横幅（指定：px）
$width-right-column: 0 !default;
$width-left-column:  0 !default;

// メインカラムとサイドカラムの間隔（指定：px）
$main-side-margin: 0 !default; // PC時
$main-top-margin:  0 !default; // タブレット & スマートフォン時

// container を fluid にするか否か。する場合は true
$container-fluid: true !default;

// container の左右の padding（指定：px）
$container-padding: (
  sm: 16,
  md: 20,
  lg: 30
  ) !default;

// headerの高さ（指定：px）
$height-header: (
  sm: 44,
  md: 55,
  lg: 64
  ) !default;

// main と footer の間隔
$footer-padding-top: (
  sm: 1.6rem,
  md: 2rem,
  lg: 0
  ) !default;

// header & main & footer の z-index
$z-index: (
  header: 2,
  main:   0,
  footer: 1
  ) !default;

// ---------------------------------------------------------------- grid

$grid-columns: 12 !default; // grid 内のカラム数

// カラム同士の間隔（指定：px）
$grid-margin: (
  sm: 16,
  md: 20,
  lg: 20
  ) !default;

// ---------------------------------------------------------------- Fonts

@font-face {
  font-family: 'Noto Sans JP';
  src: url('../fonts/NotoSansJP-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'Smooch Sans';
  src: url('../fonts/SmoochSans-VariableFont_wght.ttf') format('truetype');
}

$font-family: "YakuHanJPs", -apple-system  BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, arial, sans-serif;

$latin: "Smooch Sans", sans-serif;
$jp: "Noto Sans JP", sans-serif;

.latin{
  font-family: $latin;
  font-size: 1.125em;
}
.jp{
  font-family: $jp;
}

// ベースフォントカラー
$base-font-color: #333 !default;

// ベースの文字サイズ（指定：px）
$base-font-size: (
  sm: 13,
  md: 14,
  lg: 16
  ) !default;

// 見出し用フォントサイズ
$h1-font-size:   2.0em;
$h2-font-size:   1.8em;
$h3-font-size:   1.4em;
$h4-font-size:   1.3em;
$h5-font-size:   1.2em;
$h6-font-size:   1.1em;
$h-scale-medium: 1.15;    // md用（掛け率）
$h-scale-large:  1.1875;  // lg用（掛け率）

// 汎用フォントサイズ
$font-size: (
  'exlarge': 200%,
  'xxlarge': 150%,
  'xlarge':  125%,
  'large':   112.5%,
  'medium':  87.5%,
  'small':   75%,
  'xsmall':  62.5%
  ) !default;

// ベースのline-height
$base-line-height: (
  sm: 1.4,
  md: 1.6,
  lg: 1.8
  ) !default;

// p タグ内の line-height
$paragraph-line-height: (
  sm: 1.6,
  md: 1.6,
  lg: 1.8
  ) !default;

// ---------------------------------------------------------------- margin & padding

// 段落の余白（単位：em）
$paragraph-margin: (
  sm: 1em,
  md: 1em,
  lg: 1em
  ) !default;

// .section-inner-upper .section-inner .section-inner-lower 上下の余白（単位：em）
$section-margin: (
  sm: 1.5em,
  md: 1.5em,
  lg: 1.5em
  ) !default;

// element & p + element & p + p の余白（単位：em）
$element-margin: (
  sm: 1em,
  md: 1em,
  lg: 1em
  ) !default;

// .hero の上下の margin（単位：em）
$hero-margin-top: (
  sm: 0em,
  md: 0em,
  lg: 0em
  ) !default;

$hero-margin-bottom: (
  sm: 2em,
  md: 2em,
  lg: 2em
  ) !default;

// ---------------------------------------------------------------- color

$body-color:    #333    !default; // デフォルトテキストカラー
$base-color:    #133a88 !default; // ベースカラー
$surface-color: #efece5;

// カラーパターン
$first-color:   #0080b1 !default;
$second-color:  #279692 !default;
$third-color:   #e24f5d !default;
$fourth-color:  #eaa50d !default;
$five-color:    #f7c964 !default;

// カスタマイズド
$cs-green-color:  #339800 !default;
$cs-orange-color: #d77735 !default;
$cs-red-color:    #d25757 !default;
$cs-blue-color:   #0772ac !default;
$cs-purple-color: #904a98 !default;
$cs-pink-color:   #d25996 !default;
$cs-gray-color:   #595959 !default;
$cs-blue-clear:   #002267 !default;
$cs-emerald-color:#139487 !default;

// グレーパターン
$gray-dark:     #333333 !default;
$gray:          #55595c !default;
$gray-light:    #818a91 !default;
$gray-lighter:  #dfe2e4 !default;
$gray-lightest: #eceeef !default;

// 汎用カラー
$white-color:  #fff    !default; // 白
$black-color:  #000    !default; // 黒
$red-color:    #bc3939 !default; // 赤
$yellow-color: #fff000 !default; // 黄
$orange-color: #f18f2c !default; // 橙
$green-color:  #63a764 !default; // 緑
$blue-color:   #005fcc !default; // 青
$pink-color:   #ff0066 !default; // ピンク

// バックグラウンドカラー
$background-body:      #fff        !default; // body
$background-container: transparent !default; // container
$background-hero:      $base-color !default; // hero
//$background-header:    $surface-color     !default; // header
//$background-footer:    #f2f3f7     !default; // footer

// ---------------------------------------------------------------- アンカーテキスト

$link-color:            $base-color !default;
$link-decoration:       none !default; // or underline
$link-hover-color:      rgba($link-color, 0.8) !default;
$link-hover-decoration: none !default; // or underline

// ---------------------------------------------------------------- button

// ボタンの中 上下の padding
$button-padding-tb: (
  sm: 1em,
  md: 1em,
  lg: 1em
  ) !default;

// ボタンの中 左右の padding
$button-padding-lr: (
  sm: 1em,
  md: .8em,
  lg: .8em
  ) !default;

$button-border-radius: 5px !default; // 角丸
$button-border: 1px !default;        // アウトライン時のボーダーの太さ

// ---------------------------------------------------------------- horizontal rule ( hr )

$hr-border: 1px solid $gray-lighter !default; // ボーダーの種類

// ボーダー上下の間隔
$hr-margin: (
  sm: 1em,
  md: 1em,
  lg: 1em
  ) !default;

// ---------------------------------------------------------------- table

$table-border-color: #ccc !default;    // tableのボーダーの色
$table-th-bg-color:  #f5f4ed !default; // th のバックグラウンドカラー
$table-td-bg-color:  #fff !default;    // td のバックグラウンドカラー

// ---------------------------------------------------------------- icon ( Fontawesome )

$icon-arrows:    '\f138'; // 丸あり f138 丸なし  f054
$icon-pdf:       '\f1c1'; // PDF
$icon-window:    '\f2d2'; // 別ウインドウ
$icon-telephone: '\f098'; // 電話
$icon-mail:      '\f003'; // メール
$icon-fax:       '\f1ac'; // FAX
$icon-privacy:   '\f13e'; // プライバシーポリシー
$icon-link:      '\f0c1'; // リンク
$icon-external:  '\f08e'; // 外部リンク
$icon-file:      '\f0f6'; // ファイル

$scrollbar-width: 5px;
$scrollbar-gap: 5px;
