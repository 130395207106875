@charset "UTF-8";

//担当医表

.schedule-wrap{
  section:first-child{
    margin-top: -1.5em;
  }
  section:last-child{
    margin-bottom: -1.5em;
  }
}

table.schedule{
  th,td{
    padding: 0.5em;
  }
  .time-box-exp{
    font-size: 0.8em;
    font-weight: normal;
    margin-top: .25em;
    & span{
      font-weight: bold;
      color: $base-color;
    }
  }
  .schedule_content{
    text-align: center;
    .doctor{
      font-weight: bold;
      padding-bottom: .25em;
    }
    .comment, .comment_sub {
      font-size: .7em;
    }
    &+.schedule_content{
      margin-top: .5em;
    }
  }
  .fc-pink {
    color: $pink-color;
  }
  .fc-blue {
    color: $blue-color;
  }
  .fc-gray-dark {
    color: $gray-dark;
  }
}

.schedule.column-table{
  & .timetable{
    width: 10%;
  }
  & .time-box-exp{
    & span{
      display: inline-block;
    }
  }
}

.schedule.row-table{
  table-layout: fixed;
  & .time-box-exp{
    & span{
      display: block;
    }
  }
}

//休診代診

table.closure{
  th,td{
    //padding: 0.5em;
  }
}