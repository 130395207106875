@charset "UTF-8";

.hero {
  @include core-hero;
  color: inherit;
  padding: 4em 0;

  .main-title {
    color: #fff;
    font-size: 3em;
    font-weight: bold;
    line-height: 1.2;
  }
}
