@charset "UTF-8";

ul, ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;

  &.li-mb {
    & > li:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  &.indent {
    & li {
      & ul {
        margin-left: 1em;
      }
    }
  }
  
}

// UL
ul.disc {
  list-style: disc outside;
  padding-left: 1.5em;
}

ul.square {
  & li {
    line-height: inherit;
    padding-left: 1.5em;
    position: relative;
    &:before {
      content: '■';
      color: $base-color;
      margin-right: 0.4em;
      position: absolute;
      left: 0;
    }
  }
}

ul.circle {
  & li {
    line-height: inherit;
    padding-left: 1.5em;
    position: relative;
    &:before {
      content: '●';
      color: $base-color;
      margin-right: 0.4em;
      position: absolute;
      left: 0;
    }
  }
}

.list-horizon {
  // display: flex;
  // flex-flow: row wrap;
  // & li {
  //   & + li::before {
  //     content: ' ';
  //     padding-right: .5em;
  //     padding-left: .5em;
  //   }
  // }
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  li {
    &:not(:last-child){
      margin-right: 1em;
    }
  }
}

// OL
ol.num {
  list-style: decimal outside;
  padding-left: 1.5em;
}

ol.maru-num {
  li{
    padding-left: 1.5em;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
    }
    @for $i from 0 through 9 {
      $num: '0' + ( 2460 + $i );
      $result: '\\#{$num}';
      &:nth-child(#{$i + 1})::before {
        content: #{'"' + #{$result} + '"'};
      }
    }
  }
}

// DL
dl {
  & dt {
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 1em; }
  }
  & dd {
    line-height: 1.4;
  }
}

dl.dl-horizon {
  & dt, dd {
    line-height: 1.4;
  }
  & dt {
    float: none;
    margin-bottom: .4em;
    @include media(md) {
      width: 10em;
      float: left;
      clear: left;
      margin-top: 0;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  & dd {
    margin-left: 0;
    margin-bottom: .4em;
    @include media(md) {
      margin-left: 11.25em;
    }
  }
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

dl.dl-horizon-left {
  & dt, dd {
    line-height: 1.4;
  }
  & dt {
    float: none;
    margin-bottom: .25em;
    @include media(xl) {
      width: 10em;
      float: left;
      clear: left;
      margin-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.5;
    }
  }
  & dd {
    margin-left: 0;
    margin-bottom: .25em;
    line-height: 1.5;
    @include media(xl) {
      margin-left: 11.25em;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  &::after {
    content: "";
    display: block;
    clear: both;
  }
  &.dt-04{
    & dt{
      @include media(xl) {
        width: 4em;
      }
    }
    & dd {
      @include media(xl) {
        margin-left: 4em;
      }
    }
  }
  &.dt-06{
    & dt{
      @include media(xl) {
        width: 6em;
      }
    }
    & dd {
      @include media(xl) {
        margin-left: 6em;
      }
    }
  }
  &.dt-08{
    & dt{
      @include media(xl) {
        width: 8em;
      }
    }
    & dd {
      @include media(xl) {
        margin-left: 8em;
      }
    }
  }
  &.dt-10{
    & dt{
      @include media(xl) {
        width: 10em;
      }
    }
    & dd {
      @include media(xl) {
        margin-left: 10em;
      }
    }
  }
  &.dt-12{
    & dt{
      @include media(xl) {
        width: 12em;
      }
    }
    & dd {
     @include media(xl) {
        margin-left: 12em;
      }
    }
  }
  &.dt-14{
    & dt{
      @include media(xl) {
        width: 14em;
      }
    }
    & dd {
      @include media(xl) {
        margin-left: 14em;
      }
    }
  }
  &.dt-16{
    & dt{
      @include media(xl) {
        width: 16em;
      }
    }
    & dd {
      @include media(xl) {
        margin-left: 16em;
      }
    }
  }
  &.dt-18{
    & dt{
      @include media(xl) {
        width: 18em;
      }
    }
    & dd {
      @include media(xl) {
        margin-left: 18em;
      }
    }
  }
  &.dt-20{
    & dt{
      @include media(xl) {
        width: 20em;
      }
    }
    & dd {
      @include media(xl) {
        margin-left: 20em;
      }
    }
  }
}


