@charset "UTF-8";

.anchor-link{
  display: flex;
  width: 100%;
  border: solid 1px $base-color;
  padding: 0.75em 3em 0.75em 1em;
  background-color: #fff;
  font-size: 0.9375em;
  position: relative;
  line-height: 1.5;
  align-items: center;
  font-weight: bold;
  &::after{
    content: "";
    background: url( $root-path + "assets/img/icon/icon_arrow_bl.svg" );
    background-size: contain;
    background-repeat: no-repeat;
    width: 0.75em;
    height: 0.75em;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%) rotate(90deg);
    display: block;
  }
  &.height{
    height: 100%;
  }
}