@charset "UTF-8";

.breadlist-wrapper {
  background: transparent;
  z-index: 2;
  padding: 2em 2em 0 2em;
  font-size: 1.25em;
  background: rgba(#fff, 1);
  @include media(lg) {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1.25em;
    background: none;
  }
}

.breadlist {
  display: flex;
  background: transparent;
  border-radius: .4rem;
  font-size: 0.725em;
  list-style: none;
  flex-wrap: wrap;
  // clearfix
  &::after {
    display: table;
    clear: both;
    content: "";
  }
  & li {
    line-height: 2;
    color: #000;
    white-space: nowrap;
    //overflow: hidden;
    text-overflow: ellipsis;
    @include media(lg) {
      line-height: 1;
    }
    & + li::before {
      padding-right: .5em;
      padding-left: .5em;
      color: #000;
      content: "＞";
    }

    &:first-child{
      position: relative;
      padding-left: 1.5em;
      &::before{
        content: "";
        background: url( $img-path + 'icon/icon_home.svg' )no-repeat;
        width: 1em;
        height: 1em;
        display: inline-block;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    & a {
      color: #000;
      transition: 0.25s all ease 0s;
      &::active, &::visited {
        color: #000;
      }
      &:hover{
        opacity: 0.6;
      }
    }

    &.active {
      color: #333;
    }
  }
}
