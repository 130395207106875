@charset "UTF-8";

html{
  color: $base-font-color;
  //@include media(lg) {
    overflow-y: scroll;
    overflow-x: hidden;
  //}
}

a[href^="tel:"] {
  @include media(lg) {
    color: $base-font-color;
    pointer-events: none;
    cursor: text;
  }
}

.main-wrap {
  animation: fadeIn 0.5s ease 0s 1 normal;
}

@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

.row {
  @include media(lg) {
    flex-direction: row;
  }
  main{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media(lg) {
      width: 100%;
      padding-left: $side-width;
      overflow: visible;
    }
    .main-column{
    align-self: stretch;
    position: relative;
      @include media(lg) {
        position: relative;
        padding: 3em 0;
      }
    }
  }
}

figure{
  figcaption{
    line-height: 1.5;
    margin-top: 1em;
  }
}