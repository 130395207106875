@charset "UTF-8";

//
$icon-type: (
  'arrows':    $icon-arrows,
  'pdf':       $icon-pdf,
  'window':    $icon-window,
  'tel':       $icon-telephone,
  'mail':      $icon-mail,
  'fax':       $icon-fax,
  'privacy':   $icon-privacy,
  'link':      $icon-link,
  'external':  $icon-external,
  'file':      $icon-file
) !default;
//

// デフォルトの疑似要素を定義
@mixin icon-default {
  font-family: FontAwesome;
  content: $icon-arrows;
  color: inherit;
  display: inline-block;
  width: 1.28571429em;
  text-align: center;
}

// 各種アイコンの定義
@mixin icon-type {
  @each $name, $icon in $icon-type {
    &.#{$name} {
      &::after {
        content: $icon !important;
      }
      &.left  {
        &::after {
          content: none !important;
        }
        &::before {
          content: $icon !important;
        }
      }
    }
  }
}
