// mixins
@charset "UTF-8";

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  &:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
}

@mixin button-base {
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  line-height: 1.5 !important;
  text-align: center;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
  padding: map($button-padding-tb, sm) map($button-padding-lr, sm);
  @include media(md) {
    padding: map($button-padding-tb, md) map($button-padding-lr, md);
  }
  @include media(lg) {
    padding: map($button-padding-tb, lg) map($button-padding-lr, lg);
  }
}
