@charset "UTF-8";

.label {
  background-color: $base-color;
  border-radius: 0px;
  color: #fff;
  display: inline-block;
  font-size: 75%;
  line-height: 1 !important;
  padding: .5em .6em .6em;
  text-align: center;
  position: relative;

  &.small {
    font-size: 68.75%;
  }

  &.large {
    font-size: 100%;
    padding: 0.075em 0.6em;
  }
  @include default-background-color; // バックグラウンドカラー

  &.outline {
    background: #fff;
    border: 1px solid $base-color;
    color: $base-color;
    @include border-font-color; // ボーダー&テキストのカラー
  }

  &.expanded {
    display: block;
  }
}

span.label {
  margin-right: 0.5em;
}
