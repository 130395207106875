@charset "UTF-8";

// ---------------------------------------------------------------- grid
.grid {
  display: flex;
  flex-flow: row wrap;
  margin: (- grid-margin(sm)) (- grid-margin(sm));
  @include media(md) {
    //max-width: width(md);
    margin: (- grid-margin(md)) (- grid-margin(md));
  }
  @include media(lg) {
    //max-width: width(lg);
    margin: (- grid-margin(lg)) (- grid-margin(lg));
  }
  // & +.grid {
  //   margin: 0 (- grid-margin(sm));
  //   @include media(md) {
  //     //max-width: width(md);
  //     margin: 0 (- grid-margin(md));
  //   }
  //   @include media(lg) {
  //     //max-width: width(lg);
  //     margin: 0 (- grid-margin(lg));
  //   }
  // }
  // & .grid {
  //   margin: (- grid-margin(sm));
  //   &.gapless {
  //     margin: 0;
  //   }
  //   @include media(md) {
  //     margin: 0 (- grid-margin(md));
  //   }
  //   @include media(lg) {
  //     margin: 0 (- grid-margin(lg));
  //   }
  // }

  &.gapless {
    margin: grid-margin(sm) 0 0;
    & + .gapless {
      margin: 1rem 0 ;
      @include media(lg) {
        margin: 2rem 0;
      }
    }
    & .grid {
      & .grid {
        margin: (- grid-margin(sm));
        @include media(lg) {
          margin: grid-margin(lg, 2) (- grid-margin(lg));
        }
      }
    }
    @include media(lg) {
      margin: grid-margin(lg) 0;
    }
  }
}

// ---------------------------------------------------------------- col
.col {
  padding: grid-margin(sm);
  flex: 1 1 0;
  @include media(md) {
    padding: grid-margin(md);
    width: auto;
  }
  @include media(lg) {
    padding: grid-margin(lg);
  }
  & figure {
    text-align: center;
  }

  & > .col {
    padding: grid-margin(sm) 0;
    margin: (- grid-margin(sm)) 0 grid-margin(sm);
    @include media(md) {
      padding: grid-margin(md) 0;
      margin: (- grid-margin(md)) 0 grid-margin(md);
    }
    @include media(lg) {
      padding: grid-margin(lg) 0;
      margin: (- grid-margin(lg)) 0 grid-margin(lg);
    }
  }

  & > .grid {
    margin: (- grid-margin(sm));
    & + .grid {
      margin: grid-margin(sm) (- grid-margin(sm)) (- grid-margin(sm));
      .gapless & {
        margin: 0;
      }
    }
    @include media(md) {
      margin: (- grid-margin(md));
      & + .grid {
        margin: grid-margin(md) (- grid-margin(md)) (- grid-margin(md));
      }
    }
    @include media(lg) {
      margin: (- grid-margin(lg));
      & + .grid {
        margin: grid-margin(lg) (- grid-margin(lg)) (- grid-margin(lg));
      }
    }
  }

  & > .grid.gapless {
    margin: grid-margin(sm) 0;

    & + .grid.gapless {
      margin: (- grid-margin(sm)) 0 0;
    }

    @include media(lg) {
      margin: grid-margin(lg) 0;
      & + .grid.gapless {
        margin: (- grid-margin(lg)) 0 0;
      }
    }
  }
}

// ---------------------------------------------------------------- raw-col
.raw-col {
  text-align: center;
  padding: grid-margin(sm);
  @include media(md) {
    padding: grid-margin(md);
    width: auto;
  }
  @include media(lg) {
    padding: grid-margin(lg);
  }
  & figure {
    text-align: center;
  }
}

// ---------------------------------------------------------------- flex items 12 column
@for $i from 1 through $grid-columns {
  .sm-#{$i} {
    @include make-grid(100%/($grid-columns / $i));
  }
  .sm-#{$i}-center {
    @include make-grid(100%/($grid-columns / $i));
    margin: 0 auto;
  }
  .sm-offset-#{$i} {
    margin-left:(100%/($grid-columns / $i));
  }
}
@include media(md) {
  @for $i from 1 through $grid-columns {
    .md-#{$i} {
      @include make-grid(100%/($grid-columns / $i));
    }
    .md-#{$i}-center {
      @include make-grid(100%/($grid-columns / $i));
      margin: 0 auto;
    }
    .md-offset-#{$i} {
      margin-left:(100%/($grid-columns / $i));
    }
  }
}
@include media(lg) {
  @for $i from 1 through $grid-columns {
    .lg-#{$i} {
      @include make-grid(100%/($grid-columns / $i));
    }
    .lg-#{$i}-center {
      @include make-grid(100%/($grid-columns / $i));
      margin: 0 auto;
    }
    .lg-offset-#{$i} {
      margin-left:(100%/($grid-columns / $i));
    }
  }
}
@for $i from 1 through $grid-columns {
  .col-#{$i} {
    @include make-grid(100%/($grid-columns / $i));
  }
}

// ---------------------------------------------------------------- grid-flex
.grid-flex {
  display: flex;
  flex-flow: row wrap;
  margin: (- grid-margin(sm)) (- grid-margin(sm));
  @include media(md) {
    margin: (- grid-margin(md)) (- grid-margin(md));
  }
  @include media(lg) {
    margin: (- grid-margin(lg)) (- grid-margin(lg));
  }
  & .grid, & .grid-flex, & .grid-tile {
    margin: (- grid-margin(sm)) (- grid-margin(sm));
    @include media(md) {
      margin: (- grid-margin(md)) (- grid-margin(md));
    }
    @include media(lg) {
      margin: (- grid-margin(lg)) (- grid-margin(lg));
    }
  }
}

.column {
  padding: grid-margin(sm);
  flex: 0 auto;
  @include media(md) {
    padding: grid-margin(md);
  }
  @include media(lg) {
    padding: grid-margin(lg);
  }
  &.narrow {
    flex: 1;
  }
  @for $i from 1 through 20 {
   &.w#{$i * 5} {
      flex-basis: #{$i * 5%};
    }
  }
}

// ---------------------------------------------------------------- grid-tile
.grid-tile {
  display: flex;
  flex-flow: row wrap;
  margin: (- grid-margin(sm)) (- grid-margin(sm)) 0;
  @include media(md) {
    //max-width: width(md);
    margin: (- grid-margin(md)) (- grid-margin(md)) 0;
  }
  @include media(lg) {
    //max-width: width(lg);
    margin: (- grid-margin(lg)) (- grid-margin(lg)) 0;
  }

  @for $i from 1 through $grid-columns {
    .sm-#{$i} {
      @include make-grid(100%/$i);
    }
  }

  @include media(md) {
    @for $i from 1 through $grid-columns {
      .md-#{$i} {
        @include make-grid(100%/$i);
      }
    }
  }

  @include media(lg) {
    @for $i from 1 through $grid-columns {
      .lg-#{$i} {
        @include make-grid(100%/$i);
      }
    }
  }
  & +.grid, & +.grid-flex, & +.grid-tile {
    margin: 0 (- grid-margin(sm)) 0;
    @include media(md) {
      max-width: width(md);
      margin: 0 (- grid-margin(md)) 0;
    }
    @include media(lg) {
      max-width: width(lg);
      margin: 0 (- grid-margin(lg)) 0;
    }
  }
}

// ---------------------------------------------------------------- flex item の揃え方
.align-end {
  justify-content: flex-end; }
.align-center {
  justify-content: center; }
.align-justify {
  justify-content: space-between; }
.align-spaced {
  justify-content: space-around; }

// 縦組み  旧Android iOS6以前は未対応
.align-end-vertical {
  align-content: flex-end; }
.align-center-vertical {
  align-content: center; }
.align-justify-vertical {
  align-content: space-between; }
.align-spaced-vertical {
  align-content: space-around; }

// 子要素の天地の揃え align-itemsは親要素・align-selfは子要素
.item-top {
  align-items: flex-start; }
.item-top-child {
  align-self: flex-start; }

.item-end {
  align-items: flex-end; }
.item-end-child {
  align-self: flex-end; }

.item-center {
  align-items: center; }
.item-center-child {
  align-self: center; }

.item-stretch {
  align-items: stretch; }
.item-stretch-child {
  align-self: stretch; }

// ---------------------------------------------------------------- order

// .order-0to1 {
//   order: 0;
//   @include media(lg) {
//     order: 1;
//   }
// }
// .order-1to0 {
//   order: 1;
//   @include media(lg) {
//     order: 0;
//   }
// }

@for $i from 0 through 11 {
  .order-#{$i} {
    order: #{$i};
  }
}

.md-order-1 {
  order: 0;
  @include media(md) {
    order: 1;
  }
}

.lg-order-1 {
  order: 0;
  @include media(md) {
    order: 1;
  }
}