@charset "UTF-8";

// background color
@include background-color;

// font-size
@include font-size;

// font color
@include font-color;

// text-align
@include text-align;

// 汎用 margin padding
@include common-margin-padding;

// 汎用 common element
@include common-element;

// 段落の余白
.element {
  @include element-spacing;
  & + .element,
  & + p {
    @include element-spacing-top;
  }
}
p {
  & + p,
  & + .grid,
  & + .grid-flex,
  & + .grid-tile {
    @include element-spacing-top;
  }
}
.grid,
.grid-flex,
.grid-tile {
  & + p {
    @include element-spacing-top;
  }
}

.sp-hide{
  display: none;
   @include media(md) {
    display: block;
   }
 }

 .dt-hide{
  display: block;
   @include media(md) {
    display: none;
   }
 }

 img.border{
  border: solid 1px #ccc;
 }

.flex-table-cell{
  display: flex;
  > div{
    display: table-cell;
    width: 100%;
  }
}
_:-ms-lang(x), .flex-table-cell {
 > div{
    display: block;
    height: 100%;
  }
}

.flex-center{
  display: flex;
  justify-content: center;
}

a > img{
  width: 100%;
}

ul.notes {
  li {
    line-height: inherit;
    padding-left: 1.5em;
    position: relative;
    &:before {
      font-family: jp;
      content: '※';
      position: absolute;
      left: 0;
    }
  }
}

span.notes {
  display: inline;
  padding-left: 1.2em;
  position: relative;
  &:before {
    font-family: jp;
    content: '※';
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1.6;
  }
}

.fw-bold{
  font-weight: bold;
}

////////////////////////////////////////////////////////////
// 指定区間のみ表示
////////////////////////////////////////////////////////////

.show-only-sm{
  @include media(md) {
    display: none !important;
  }
}
.show-only-md{
  display: none !important;
  @include media(md) {
    display: inherit !important;
  }
  @include media(lg) {
    display: none !important;
  }
}
.show-only-lg{
  display: none !important;
  @include media(lg) {
    display: inherit !important;
  }
  @include media(xl) {
    display: none !important;
  }
}
.show-only-xl{
  display: none !important;
  @include media(xl) {
    display: inherit !important;
  }
}

////////////////////////////////////////////////////////////
// 指定区間のみ非表示
////////////////////////////////////////////////////////////

.hide-only-sm{
  display: none !important;
  @include media(md) {
    display: inherit !important;
  }
}
.hide-only-md{
  @include media(md) {
    display: none !important;
  }
  @include media(lg) {
    display: inherit !important;
  }
}
.hide-only-lg{
  @include media(lg) {
    display: none !important;
  }
  @include media(xl) {
    display: inherit !important;
  }
}
.hide-only-lg{
  @include media(xl) {
    display: none !important;
  }
}

////////////////////////////////////////////////////////////
// 指定区間　以上を表示 or 以下を非表示
////////////////////////////////////////////////////////////

.show-over-md,
.hide-under-md{
  display: none !important;
  @include media(md) {
    display: inherit !important;
  }
}
.show-over-lg,
.hide-under-lg{
  display: none !important;
  @include media(lg) {
    display: inherit !important;
  }
}
.show-over-xl,
.hide-under-xl{
  display: none !important;
  @include media(xl) {
    display: inherit !important;
  }
}

////////////////////////////////////////////////////////////
// 指定区間　以下を表示 or 以上を非表示
////////////////////////////////////////////////////////////

.show-under-md,
.hide-over-md{
  display: inherit !important;
  @include media(md) {
    display: none !important;
  }
}

.show-under-lg,
.hide-over-lg{
  display: inherit !important;
  @include media(lg) {
    display: none !important;
  }
}

.show-under-xl,
.hide-over-xl{
  display: inherit !important;
  @include media(xl) {
    display: none !important;
  }
}

.form-description-box{
  border: solid 1px $base-color;
  padding: 2em;
  .emphasis-box{
    background: $surface-color;
    padding: 1em 1.5em;
  }
}

.spacer {
  margin-top: 1.6rem !important;
  @include media(lg) {
    margin-top: 2.4rem !important;
  }
}

//動画.map埋め込み
.iframe-wrapper {
  position: relative;
  width: 100%;
  &:before {
    content:"";
    display: block;
    padding-top: 56.25%; /* 高さと幅の比を16:9に固定。9/16*100=56.25 */
  }
  .iframe-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}