@charset "UTF-8";

@mixin core-hero {
  background: $background-hero;
  margin-top: map($hero-margin-top, sm);
  margin-bottom: map($hero-margin-bottom, sm);
  position: relative;
  width: 100%;
  @include media(md) {
    margin-top: map($hero-margin-top, md);
    margin-bottom: map($hero-margin-bottom, md);
  }
  @include media(lg) {
    margin-top: map($hero-margin-top, lg);
    margin-bottom: map($hero-margin-bottom, lg);
  }
}
