@charset "UTF-8";

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-trigger {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  height: 2.5em;
  font-size: 1em;
  line-height: 1.42857143;
  padding: 6px 32px 6px 12px;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  //display: block;
  //outline: none;
  //overflow: hidden;

  &:after {
    border: 1px solid #999;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 7px;
    pointer-events: none;
    position: absolute;
    transform: rotate(-45deg);
    width: 7px;
    margin-top: -5px;
    right: 16px;
    top: 50%;
  }
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  z-index: 100;
  min-width: 10rem;
  max-width: 25rem;
  max-height: none;
  line-height: 1.5;
  font-size: inherit;
  font-weight: inherit;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: .25rem;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
  display: none;
  padding: .25rem 0;
  overflow-y: auto;
  transform: translateZ(0);
  user-select: none;

  & .dropdown-heading {
    font-size: 80%;
    font-weight: inherit;
    color: #aaa;
    padding: .25rem calc(1rem + .5em);
    cursor: default
  }

  & hr {
    width: 100%;
    margin: .25rem 0;
  }

  & a {
    position: relative;
    color: $body-color;
    text-decoration: none;
    padding: .25rem calc(1rem + .5em);
    display: block;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: $base-color;
      color: #fff;
      text-decoration: $link-hover-decoration;
    }

    &:focus {
      outline: none;
      color: #111;
      background-color: #f2f2f2;
    }

    &.disabled {
      background-color: transparent;
      color: #111;
      outline: none;
      cursor: not-allowed;
      opacity: .5;
    }

    &.checked:before {
      position: absolute;
      left: calc(.5rem - .1em);
      content: "✓";
      font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue;
      font-weight: 600
    }
  }
}

.dropdown-top .dropdown-menu {
  top: auto;
  bottom: calc(100% + 1px)
}

.dropdown-left .dropdown-menu {
  left: auto;
  right: 0
}

.dropdown.active .dropdown-menu {
  display: block
}
