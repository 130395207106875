@charset "UTF-8";

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
  margin: 0;
	padding: 0;

  & th, td {
    border: 1px solid $table-border-color;
    padding: 1em 1.5em;
  }
  & th {
    background: $table-th-bg-color;
    &.title{
      background: $base-color;
      color: #fff;
    }
  }
  & td {
    background: $table-td-bg-color;
  }
  //thの幅指定（tableにつける）
  @for $i from 1 through 20 {
    &.th#{$i * 5} th{
      width: #{$i * 5%};
    }
  }
}

// th block
.th-block {
  & th, td {
    display: block;
    @include media(md) {
      display: table-cell;
    }
  }
  & th {
    width: 100%;
  }
  & td {
    border-top: none;
    border-bottom: none;

    @include media(md) {
      border-top: 1px solid $table-border-color;
      border-bottom: 1px solid $table-border-color;
    }
  }
  & tr:last-child td {
      border-bottom: 1px solid $table-border-color;
  }
  //th-blockのときのthの幅指定（tableにつける）
    @for $i from 1 through 20 {
      &.th#{$i * 5} th{
        width: 100%;
        @include media(md) {
          width: #{$i * 5%};
        }
      }
    }
}

// 装飾なし
.table-clear {
  margin: 0;
  & th, td {
    background: #fff;
    border: none;
    padding: 0;
  }

  &.confirm { // フォームの確認画面用
    & th, td {
      display: block;
      @include media(md) {
        border-bottom: 24px solid #fff;
        display: table-cell;
      }
    }
    & th {
      background: #ebf3f9;
      color: $base-color;
      font-size: 1.2rem;
      font-weight: normal;
      text-align: left;
      padding: .2em 1em;
      width: 100%;
      @include media(md) {
        font-size: 1.3rem;
        width: 28%;
      }
      @include media(lg) {
        font-size: 1.4rem;
      }
    }
    & td {
      padding: 1em;
      @include media(md) {
        padding: .2em .5em .2em 1em;
      }
    }
  }
}

// スクロールを出すためのwrapper
.table-scroll {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(md) {
    white-space: normal;
  }
  & table.fixed{
    table-layout: auto;
  }
}

table.fixed{
  table-layout: fixed;
}