@charset "UTF-8";

.tabs-nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
  & > li {
    color: #07d;
    position: relative;
    width: 50%;
    padding: 0 0.5em;
    & a {
      background: #efede0;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom: 4px solid #fff;
      display: block;
      padding: 1.2rem;
      text-align: center;
      color: $base-font-color;
      transition: 0.25s ease all 0s;
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
    &.current a {
      border-bottom: 4px solid $base-color;
      background: $base-color;
      color: #fff;
    }
  }
}

.tabs-content {
  padding-top: 1em;
}