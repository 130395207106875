@charset "UTF-8";

.accordion {
  & dt {
    background: $base-color;
    color: #fff;
    cursor: pointer;
    font-weight: normal;
    margin: 0;
    padding: 1rem 1rem 1rem 2rem;
    position: relative;
    border-radius: 5px;
    &:not(:first-child) {
      border-top: 1px solid #fff;
      margin: 0;
    }
    &:after {
      font-family: FontAwesome;
      position: absolute;
      top: 50%;
      right: 1.6rem;
      content: '\f067';
      display: inline-block;
      transform: translateY(-50%);
      transition: transform 0.3s;
    }
    &.ac-open:after {
      transform: translateY(-50%) rotate(45deg);
    }
    &:not(:first-of-type){
      margin-top: 1em;
    }
  }
  & dd {
    display: none;
    padding: 1em 0;
    &.ac-current{
      display: block;
    }
  }
  
}
