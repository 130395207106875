@charset "UTF-8";

.panel {
  background-color: #fff;
  border: 1px solid $gray-lighter;
  border-radius: 0px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  &.height {
    height: 100%;
  }
  &.default{
    background: $table-th-bg-color;
    border-radius: 0.25em;
    padding: 1em 1.5em;
    & .panel-title{
      font-weight: bold;
      color: $base-color;
      font-size: 1.125em;
      padding-bottom: 0.5em;
      line-height: 1.5;
      text-align: center;
    }
  }
}

.panel-header {
  padding: 0.75em 1em;
  border-bottom: 1px solid $gray-lighter;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.panel-body {
  padding: 1em;
}

.panel-footer {
  padding: 0.5em 1em;
  background-color: #f5f5f5;
  border-top: 1px solid $gray-lighter;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
