@charset "UTF-8";

//
$color-pattern: (
  'base':          $base-color,
  'first':         $first-color,
  'second':        $second-color,
  'third':         $third-color,
  'fourth':        $fourth-color,
  'five':          $five-color,
  'gray-dark':     $gray-dark,
  'gray':          $gray,
  'gray-light':    $gray-light,
  'gray-lighter':  $gray-lighter,
  'gray-lightest': $gray-lightest,
  'white':         $white-color,
  'black':         $black-color,
  'red':           $red-color,
  'blue':          $blue-color,
  'yellow':        $yellow-color,
  'orange':        $orange-color,
  'green':         $green-color
  ) !default;
//

$remove-color-pattern: map-remove( $color-pattern, 'base' );

// font color
@mixin font-color {
  @each $name, $color in $color-pattern {
    .fc-#{$name} {
      color: $color;
    }
  }
}

// background color
@mixin default-background-color {
  @each $name, $color in $remove-color-pattern {
    &.#{$name} {
      background-color: $color;
    }
  }
}
@mixin background-color {
  @each $name, $color in $color-pattern {
    .bg-#{$name} {
      background-color: $color;
      &.light {
        background-color: lighten( $color, 30% );
      }
    }
  }
}

// block header
@mixin block-header-background {
  @each $name, $color in $color-pattern {
    .#{$name} & {
      background-color: $color;
    }
  }
}

// border color
@mixin border-color {
  @each $name, $color in $remove-color-pattern {
    &.#{$name} {
      border-color: $color;
    }
  }
}
@mixin border-font-color {
  @each $name, $color in $remove-color-pattern {
    &.#{$name} {
      border-color: $color;
      color: if($color == $yellow-color, $orange-color, $color);
    }
  }
}

// Button color
@mixin button-color {
  @each $name, $color in $remove-color-pattern {
    &.#{$name} {
      background-color: $color;
      border-top: 1px solid $color;
      border-left: 1px solid $color;
      border-right: 1px solid $color;
      @if $color == $yellow-color or $color == $gray-lightest {
        color: $gray-dark;
      } @else {
        color: $white-color;
      }
      // &:hover {
      //   background-color: darken($color,10%);
      //   @if $color == $yellow-color or $color == $gray-lightest {
      //     color: $gray-dark;
      //   } @else {
      //     color: $white-color;
      //   }
      // }
    }
  }
}
@mixin button-color-outline {
  @each $name, $color in $remove-color-pattern {
    &.#{$name} {
      background-color: #fff;
      border: 1px solid $color;
      color: if($color == $yellow-color, $orange-color, $color);
      &:hover {
        background-color: rgba($color,.1);
        //color: if($color == $yellow-color, $gray, $color);
      }
    }
  }
}

// icon color
@mixin icon-color {
  @each $name, $color in $remove-color-pattern {
    & span.ic-#{$name} {
      &::after, &::before {
        color: $color;
      }
    }
  }
}
