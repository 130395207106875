@charset "UTF-8";

@mixin paragraph-style {
  font-size: inherit;
  line-height: map($paragraph-line-height, sm);
  text-rendering: optimizeLegibility;
  @include media(md) {
    line-height: map($paragraph-line-height, md);
  }
  @include media(lg) {
    line-height: map($paragraph-line-height, md);
  }
}

@mixin i-style {
  font-style: italic;
  line-height: inherit;
}

@mixin bold-style {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

@mixin span-icon-img-style {
  vertical-align: middle;
  margin: 0 0.2em;
  width: 1em;
  height: 1em;
}

@mixin heading-style {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 1.25em;
  margin-bottom: 0.8em;
  line-height: 1.4;
}

// ---------- ---------- ---------- ---------- ---------- h1
@mixin heading-one {
  font-size: $h1-font-size;
  @include media(md) {
    font-size: $h1-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h1-font-size * $h-scale-large;
  }
}

// ---------- ---------- ---------- ---------- ---------- h2
@mixin heading-two {
  font-size: $h2-font-size;
  margin-top: 0;
  @include media(md) {
    font-size: $h2-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h2-font-size * $h-scale-large;
  }
}

// ---------- ---------- ---------- ---------- ---------- h3
@mixin heading-three {
  font-size: $h3-font-size;
  @include media(md) {
    font-size: $h3-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h3-font-size * $h-scale-large;
  }
}

// ---------- ---------- ---------- ---------- ---------- h4
@mixin heading-four {
  font-size: $h4-font-size;
  @include media(md) {
    font-size: $h4-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h4-font-size * $h-scale-large;
  }
}

// ---------- ---------- ---------- ---------- ---------- h5
@mixin heading-five {
  font-size: $h5-font-size;
  @include media(md) {
    font-size: $h5-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h5-font-size * $h-scale-large;
  }
}

// ---------- ---------- ---------- ---------- ---------- h6
@mixin heading-six {
  font-size: $h6-font-size;
  @include media(md) {
    font-size: $h6-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h6-font-size * $h-scale-large;
  }
}
