@charset "UTF-8";

// グローバルナビゲーションの wrap
@mixin global-navigation {
  position: relative;
}

// ナビゲーションの wrap
@mixin sub-navigation {
  position: relative;
}
