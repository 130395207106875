@charset "UTF-8";

.top{
  .main-wrap{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
  .main-column{
    flex: 1 0 auto;
    min-height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: calc(map($section-margin, sm) * 2) 0;
    @include media(md) {
      padding: calc(map($section-margin, md) * 2) 0;
    }
    @include media(lg) {
      padding: calc(map($section-margin, lg) * 2) 0;
    }

    .container{
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      min-height: 0%;
      @include media(lg) {
        width: 100%;
      }
    }
  }
  .section-wrap{
    background: #fff;
    flex: 1 0 auto;
    min-height: 0%;
  }
  footer{
    padding-top: 0;
  }
}

.main-visual{
  position: relative;
  .visual-slider{
    .slick-slide{
      .slide-image{
        object-fit: cover;
        // height: 100%;
        // width: 100%;
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: center;
        height: 60vh;
        @include media(lg) {
          height: 80vh;
        }
      }
    }
  }
  .visual-text{
    font-family: $jp;
    font-size: 1.75em;
    font-weight: 600;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    width: 100%;
    padding: 0 container-padding(sm, 1);
    text-shadow:
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color,
    0 0 2px $base-color;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
    @include media(lg) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      text-align: right;
      padding: 0 container-padding(lg, 1);
    }
    br{
      display: none;
    }
    @include media(md) {
      br{
        display: block;
      }
    }
  }
  // &::after{
  //   content: '';
  //   width: 2.5em;
  //   height: 2.5em;
  //   display: inline-block;
  //   background: url('../img/slider/scroll.svg');
  //   position: absolute;
  //   bottom: 1em;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }

  .floating-content{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    @include media(lg) {
      position: initial;
      width: auto;
      height: auto;
      left: auto;
      top: auto;
      display: block;
    }
  }

  .floating-bnr-wrapper{
    padding: 0 0.5em 0.5em 0.5em;
    max-width: 100%;
    @include media(lg) {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      align-self: auto;
      left: auto;
      right: 0;
    }
    .floating-bnr{
      width: 300px;
      max-width: 70%;
      display: block;
      filter: drop-shadow(0 0 5px #333);
      //transition: opacity 0.5s;
      @include media(lg) {
        max-width: none;
      }
      @media (hover: hover) {
        // &:hover {
        //   opacity: 0.8;
        // }
      }
      & + .floating-bnr{
        margin-top: 0.5em;
        @include media(lg) {
          margin-top: 0;
          margin-left: 0.5em;
        }
      }
    }
  }

}

.slick-slide img {
  width: 100%;
  min-width: 100%;
}

.info-wrapper{
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  position: relative;
  border: solid 1px $base-color;
  flex-direction: column;
  @include media(md) {
    flex-direction: row;
  }
  .info-title{
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $base-color;
    padding: 1.25em;
    align-self: stretch;
    .info-heading{
      font-size: 1.625em;
      font-weight: 300;
      font-family: $jp;
      letter-spacing: 0;
      position: relative;
      padding-left: 1.5em;
      margin-bottom: 0;
      display: block;
      &::before{
        content: "";
        background: url( $img-path + 'icon/icon_flag_wh.svg' )no-repeat;
        background-size: cover;
        width: 1em;
        height: 1em;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .en{
      font-size: 1.375em;
      display: block;
      text-align: center;
      font-family: $latin;
      font-weight: 500;
    }
    & a.more{
      padding: 0.5em;
      margin-top: 1rem;
      display: block;
      border: solid 1px #fff;
      color: #fff;
      font-size: 0.8em;
      transition: 0.25s all ease 0s;
      line-height: 1;
      width: 16rem;
      text-align: center;
      position: relative;
      overflow: hidden;
      span{
        position: relative;
      }
      &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        display: block;
        background: #fff;
        left: -100%;
        top: 0;
        transition: left 0.2s;
      }
      &:hover{
        color: $base-color;
        &::before{
          left: 0;
        }
      }
    }
  }
  .info-body{
    padding: 1.5em 2.5em;
    width: 100%;
  }
}

//バナースライダ
.bnr-list{
  background: #F5F4ED;
  padding: 1.5rem 4rem;
  a{
    margin: 1rem;
    display: block;
  }
  .slick-next,
  .slick-prev{
    &::before{
      content: '';
      background-image: url('../img/icon/icon_arrow_bl.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 2rem;
      height: 2rem;
      display: inline-block;
    }
  }
  .slick-prev{
    left: 1.5rem;
    &::before{
      transform: rotate(-180deg);
    }
  }
  .slick-next{
    right: 1.5rem;
  }
}

.sns-wrapper{
  border: solid 1px #eaeaea;
  padding: 2em;
  height: 100%;
  min-height: 100%;
  .sns-heading{
    color: $base-color;
    text-align: center;
    position: relative;
    margin-bottom: 0.75em;
    padding-bottom: 0.5em;
    font-family: $latin;
    font-weight: 500;
    line-height: 1.5;
    font-size: 2em;
    @include media(md) {
      font-size: 2.25em;
    }
    @include media(lg) {
      font-size: 2.375em;
    }
    &::after{
      content: "";
      background: url( $img-path + 'border_bl.svg' )no-repeat;
      display: block;
      width: 100%;
      height: 4px;
      background-size: auto 100%;
      background-position: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    span{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 1em;
        height: 1em;
        display: inline-block;
        margin-right: 0.5em;
      }
    }
  }
  .sns-body{
    max-width: 500px;
    margin: 0 auto;
  }
}

.infolist{
  & dt,dd{
    @include media(xl) {
    padding-top: .5em; 
    padding-bottom: .5em;
    }
  }
}

.infotop{
  & dt{
    margin-top: 0.5em !important;
    @include media(xl) {
      margin-top: 0 !important;
    }
  }
}

.infolist,
.infotop{
  // a[href*=".pdf"]::after {
  //   content: "";
  //   display: inline-block;
  //   vertical-align: middle;
  //   background-image: url( $root-path + "assets/img/icon/icon_pdf_rd.svg" );
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   width: 1em;
  //   height: 1em;
  //   margin: 0 0 0.2em 0.5em;
  // }
  .icon .pdf{
    &::after{
      color: $red-color;
    }
  }
}
