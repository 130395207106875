@charset "UTF-8";

// -------------------------------------------------------------------------------------------------------- rem, em に変換して単位をつける関数
@function unit-convert($value, $unit) {
  @return $value / 10 + $unit;
}

// -------------------------------------------------------------------------------------------------------- 各プロパティの単位をつける関数
// container & row-container の padding
@function container-padding($key, $i) {
  @return map-get( $container-padding, $key ) / 10 / $i + rem;
}

@function map($value, $key, $unit: null) {
  @if( $unit == rem ) {
    @return map-get($value, $key) / 10 + $unit;
  } @else if( $unit == null ) {
    @return map-get($value, $key);
  } @else {
    @return map-get($value, $key) + $unit;
  }
}

// -------------------------------------------------------------------------------------------------------- メインコンテンツの横幅を自動計算で算出する
$width-main: ''; // 変数のリセット

@if $main-layout-column == 3  and $side-right-flag == true and $side-left-flag == true {

  $width-main: $width-main-column + $width-left-column + $width-right-column + $main-side-margin * 2 + map-get($container-padding, lg) * 2;

} @else if $main-layout-column == 2 and $side-right-flag == true and $side-left-flag == false {

  $width-main: $width-main-column + $width-right-column + $main-side-margin + map-get($container-padding, lg) * 2;

} @else if $main-layout-column == 2 and $side-right-flag == false and $side-left-flag == true {

  $width-main: $width-main-column + $width-left-column + $main-side-margin + map-get($container-padding, lg) * 2;

} @else {

  $width-main: $width-main-column + map-get($container-padding, lg) * 2;
}

$container-width: (
  md: unit-convert(map-get($breakpoints, md), rem),
  lg: unit-convert($width-main, rem)
  ) !default;

@function width($key) {
  @return map-get($container-width, $key);
}

// -------------------------------------------------------------------------------------------------------- container & row-container の flued 設定
@mixin container-fluid-flag($key: md) {
  @if $container-fluid == true {
    width: auto;
    max-width: width($key);
  } @else {
    width: width($key);
  }
}

// -------------------------------------------------------------------------------------------------------- grid
@mixin make-grid($width) {
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}
// margin
@function grid-margin($key, $i: null) {
  @if( $i != null ) {
    @return map-get($grid-margin, $key) / 2 / $i / 10 + rem;
  } @else {
    @return map-get($grid-margin, $key) / 2 / 10 + rem;
  }
}
