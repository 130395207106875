@charset "UTF-8";

// font size
@mixin font-size {
  @each $name, $size in $font-size {
    .#{$name} {
      font-size: $size;
    }
  }
}

// 段落の余白
@mixin element-spacing {
  margin: map($element-margin, sm) 0;
  @include media(md) {
    margin: map($element-margin, md) 0;
  }
  @include media(lg) {
    margin: map($element-margin, lg) 0;
  }
}

@mixin element-spacing-top {
  margin-top: map($element-margin, sm);
  @include media(md) {
    margin-top: map($element-margin, md);
  }
  @include media(lg) {
    margin-top: map($element-margin, lg);
  }
}

// 汎用 margin padding
@mixin common-margin-padding {
  @for $i from 1 through 25 {
    .mt#{$i * 4} {
      margin-top: #{($i * 4) / 10}rem !important;
      @include media(lg) {
        margin-top: #{($i * 4) / 10 * 1.5}rem !important; }
    }
    .mb#{$i * 4} {
      margin-bottom: #{($i * 4) / 10}rem !important;
      @include media(lg) {
        margin-bottom: #{($i * 4) / 10 * 1.5}rem !important; }
    }
    .mr#{$i * 4} {
      margin-right: #{($i * 4) / 10}rem !important;
      @include media(lg) {
        margin-right: #{($i * 4) / 10 * 1.5}rem !important; }
    }
    .ml#{$i * 4} {
      margin-left: #{($i * 4) / 10}rem !important;
      @include media(lg) {
        margin-left: #{($i * 4) / 10 * 1.5}rem !important; }
    }
    .pt#{$i * 4} {
      padding-top: #{($i * 4) / 10}rem !important;
      @include media(lg) {
        padding-top: #{($i * 4) / 10 * 1.5}rem !important; }
    }
    .pb#{$i * 4} {
      padding-bottom: #{($i * 4) / 10}rem !important;
      @include media(lg) {
        padding-bottom: #{($i * 4) / 10 * 1.5}rem !important; }
    }
    .pr#{$i * 4} {
      padding-right: #{($i * 4) / 10}rem !important;
      @include media(lg) {
        padding-right: #{($i * 4) / 10 * 1.5}rem !important; }
    }
    .pl#{$i * 4} {
      padding-left: #{($i * 4) / 10}rem !important;
      @include media(lg) {
        padding-left: #{($i * 4) / 10 * 1.5}rem !important; }
    }
  }

  .m0  { margin:         0 !important; }
  .mt0 { margin-top:     0 !important; }
  .mb0 { margin-bottom:  0 !important; }
  .ml0 { margin-left:    0 !important; }
  .mr0 { margin-right:   0 !important; }
  .p0  { padding:        0 !important; }
  .pt0 { padding-top:    0 !important; }
  .pb0 { padding-bottom: 0 !important; }
  .pl0 { padding-left:   0 !important; }
  .pr0 { padding-right:  0 !important; }
}

// text-align
@mixin text-align {
  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-justify {
    text-align-last: justify;
    text-justify: inter-ideograph;
  }
}

// common element
@mixin common-element {
  .clearfix:after {
    content:" ";
    display:block;
    clear:both;
  }
  .center-block {
    display: block;
    margin: 0 auto;
  }
  @for $i from 10 through 20 {
    .lh-#{$i} {
      line-height: #{$i * 0.1} !important;
    }
  }

  @for $i from 1 through 20 {
    .w#{$i * 5} {
      width: #{$i * 5%} !important;
    }
  }
}
