@charset "UTF-8";


// メニューボタン
.slicknav_btn {
  display: inline-flex;
  align-content: center;
  align-items: center;
  background: $fourth-color;
  width: 56px;
  height: 56px;
  padding: 1rem;
  position: relative;
  &:hover {
    text-decoration: none;
  }
}

.slicknav_menutxt { // ボタン内のテキスト
  display: none;
  color: #fff;
  margin-right: 0.4em;
}

.slicknav_icon { // バーガー用ボーダー
  display: block;
  width: 100%;

  & span {
    width: 100%;
    height: 3px; // 太さ
    background-color: #fff;
    display: block;
    transition: all .4s;

    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      margin: 10px 0; // 間隔
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.slicknav_open .slicknav_icon { // バーガー用ボーダー OPEN時
  background: transparent;

  & span {
    &:nth-of-type(1) {
      transform: translateY(13px) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(-13px) rotate(45deg);
    }
  }
}

// リスト
.slicknav_nav,
.slicknav_nav ul {
  overflow: hidden;
}
.slicknav_nav ul,
.slicknav_nav li {
  display: block;
}

.slicknav_nav {
  color: #fff;
  font-size: 1.2em;
  position: absolute;
  //top: $height-header + 0.0625rem;
  right: 0;
  width: 100%;
  z-index: 99;

  & ul { // 子メニュー
    & li {
      position: initial;
      & a {
        background: lighten($base-color, 10%);
      }
      & ul { // 孫メニュー
        & li {
          & a {
          }
        }
      }
    }
  }

  & > li {
    text-align: center;
    &.slicknav_parent a.slicknav_item {
      position: relative;
      &:after { // 子メニューの矢印
        font-family: FontAwesome;
        font-size: 1.6em;
        content: '\f107';
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);
      }
      & .slicknav_arrow { display: none; } // 元の矢印を消去
    }
  }

  & a {
    display: block;
    padding: 1.2em;
    color: #fff;
    background: $base-color;
    transition: background .2s;
    &.slicknav_item a {
      background: transparent;
    }

    &:hover {
      background: darken($base-color, 15%);
      color: #fff;
    }
  }

  & .slicknav_txtnode { // リンクなし
    padding: 1rem;
  }

  & .slicknav_item a {
    padding: 0;
    margin: 0;
  }

  & .slicknav_parent-link a {
    padding: 0;
    margin: 0;
  }
}

