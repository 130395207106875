@charset "UTF-8";

header {
  width: 100%;
  background: none;
  @include media(lg) {
    background: $base-color;
    width: $side-width;
    position: fixed;
    height: 100%;
    overflow: hidden;
  }
}

// .scroll-wrapper{
//   position: relative;
//   height: 100%;
// }

.scroll-content{

  -ms-overflow-style: none;
  scrollbar-width: none;

  .ps__rail-x,
  .ps__rail-y,
  .ps__thumb-x,
  .ps__thumb-y{
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @include media(lg) {
    height: 100%;
    overscroll-behavior-y: contain;

    &.ps{
      overflow-y: scroll !important;
    }

    .ps__rail-y{
      background-color: transparent !important;
      width: calc($scrollbar-gap * 2 + $scrollbar-width) !important;
      margin: $scrollbar-gap 0;
      height: 100% !important;
      top: 0 !important;
      display: block;
      .ps__thumb-y{
        width: $scrollbar-width !important;
        border-radius: $scrollbar-width !important;
        background-color: rgba(#fff , 0.2) !important;
        right: $scrollbar-gap !important;
        display: block;
      }
    }
    &:hover .ps__rail-y{
      opacity: 1 !important;
    }
  }

}

.header-inner {
  height: auto;
  color: #fff;
  @include media(lg) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% + 1px);
    padding: $layout-padding 0;
  }
  .top-box{
    @include media(lg) {
      overflow: hidden;
    }
    & > div + div{
      @include media(lg) {
        margin-top: 1em;
      }
    }
    & > div + ul{
      @include media(lg) {
        margin-top: 1em;
      }
    }
    & > ul + div{
      @include media(lg) {
        margin-top: 1em;
      }
    }
    .header-container > div + div,
    .header-container > ul + ul,
    .header-container > div + ul,
    .header-container > ul + div,
    .header-container > ol + ol,
    .header-container > div + ol,
    .header-container > ol + div,
    .header-container > ul + ol,
    .header-container > ol + ul{
      @include media (lg) {
        margin-top: 2rem;
      }
    }
  }
  .bottom-box{
    @include media(lg) {
    }
  }

  .logo{
    width: 100%;
    background: $base-color;
    padding: 1.6rem;
    @include media(lg) {
      background: none;
      padding: 0;
    }
    a{
      display: block;
      width: 25rem;
      height: 4.6rem;
      margin: 0 auto;
      background-image: url('../img/logo_sp.svg');
      background-repeat: no-repeat;
      background-size: contain;
      @include media(lg) {
        width: 29rem;
        height: 8.9rem;
        max-width: 100%;
        background-image: url('../img/logo.svg');
      }
      img{
        width: 100%;
      }
    }
    .address{
      font-size: 1em;
      display: block;
      line-height: 1.5;
      margin-top: 0.5em;
      text-align: center;
      @include media(lg) {
        text-align: left;
        font-size: 0.875em;
        margin-top: 1rem;
      }
      br{
        display: none;
        @include media(lg) {
          display: block;
        }
      }
    }
  }
  .header-link{
    display: flex;
    background: rgba(255, 255, 255, 0.9);
    justify-content: space-between;
    @include media(lg) {
      background: none;
      margin-top: 1rem !important;
      align-items: center;
    }
    .header-link-item{
      display: inline-block;
      width: 50%;
      @include media(lg) {
         width: auto;
      }
      a{
        padding: 1.4rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $base-font-color;
        text-align: center;
        @include media(lg) {
          justify-content: flex-start;
          height: auto;
        }
      }
    }
    .tel{
      margin-right: 0;
      //font-size: 1.125em;
      @include media(lg) {
         margin-right: 0.5em;
      }
      a{
        font-family: $latin;
        font-size: 1.75em;
        font-weight: 500;
        border-right: solid 1px rgba($base-color, 0.5);
        line-height: 1;
        @include media(lg) {
          font-size: 1.5em;
          pointer-events: none;
          color: #fff;
          padding: 0;
          border-right: none;
        }
        &::before{
          content: "";
          width: 1.25em;
          height: 1.25em;
          display: inline-block;
          margin-right: 0.25em;
          background: url( $root-path + "assets/img/icon/icon_phone_bl.svg" );
          background-size: contain;
          background-repeat: no-repeat;
          @include media(lg) {
            width: 1em;
            height: 1em;
            background: url( $root-path + "assets/img/icon/icon_phone_wh.svg" );
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    } 
    .access{
      @include media(lg) {
        font-size: 1em;
      }
      a{
        background: none;
        border-radius: 0.25rem;
        font-size: 1em;
        transition: background-color 0.2s;
        @include media(lg) {
          background-color: $red-color;
          color: #fff;
          padding: 0.25em 0.75em;
          font-size: 0.875em;
        }
        @include media(lg) {
          &:hover {
            background-color: darken($red-color, 10%);
          }
        }
        &::before{
          content: "";
          width: 1.5em;
          height: 1.5em;
          display: inline-block;
          margin-right: 0.25em;
          background: url( $root-path + "assets/img/icon/icon_pin_bl.svg" );
          background-size: contain;
          background-repeat: no-repeat;
          @include media(lg) {
            width: 1em;
            height: 1em;
            background: url( $root-path + "assets/img/icon/icon_pin_wh.svg" );
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}

.header-container{
  @include media(lg) {
    padding-left: calc($layout-padding);
    padding-right: calc($layout-padding);
    // @-moz-document url-prefix() {
    //   & {
    //     padding-right: calc($layout-padding);
    //   }
    // }
  }
}

//ご利用案内（PC）
.time{
  display: none;
  @include media(lg) {
    display: block;
    background: $base-color;
    padding: 1.5em;
    border: solid 1px #fff;
    //margin-top: 2rem;
  }
  .time-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #fff;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
    h2{
      font-size: 1.25em;
      font-weight: 400;
      font-family: $jp;
      position: relative;
      padding-left: 1.25em;
      margin-bottom: 0;
      letter-spacing: 0;
      &::before{
        content: "";
        background: url( $img-path + 'icon/icon_clock_wh.svg' )no-repeat;
        background-size: cover;
        width: 1em;
        height: 1em;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    span{
      font-family: $latin;
      font-size: 1.125em;
      font-weight: 500;
      padding-top: 0.2em;
      margin-left: 0.2em;
    }
  }
  .time-body{
    font-size: 0.9em;
    .annotation{
      font-size: 0.8em;
      margin-top: 0.5em;
    }
  }
}

//ご利用案内（SP）
.s-time{
  display: block;
  background: $base-color;
  padding: 1.5em;
  border: solid 1px #fff;
  .time-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #fff;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
    letter-spacing: 0.1em;
    h2{
      font-size: 1.5em;
      font-weight: 400;
      font-family: $jp;
      position: relative;
      padding-left: 1.25em;
      margin-bottom: 0;
      letter-spacing: 0;
      &::before{
        content: "";
        background: url( $img-path + 'icon/icon_clock_wh.svg' )no-repeat;
        background-size: cover;
        width: 1em;
        height: 1em;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    span{
      font-size: 1em;
      font-family: $latin;
      font-weight: 500;
      padding-top: 0.2em;
    }
  }
  .time-body{
    font-size: 1.125em;
    .annotation{
      font-size: 0.875em;
      margin-top: 0.5em;
    }
  }
}

//スクロールバー
// .mCSB_container {
//   @include media(lg) {
//     min-height: 100vh;
//   }
// }
// .mCustomScrollBox{
//   height: auto !important;
// }

// #mCSB_2_container{
//   display: flex;
//   flex-direction: column;
// }

