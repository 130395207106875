@charset "UTF-8";

// 段落
p {
  @include paragraph-style;
}

// イタリック
i {
  @include i-style;
}

// ボールド
strong,
em,
b {
  @include bold-style;
}

// アイコン用 img
span.icon {
  img {
    @include span-icon-img-style;
  }
}

// heading
h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-style;
}

// h1
h1 {
  @include heading-one;
  &.msg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $base-color;
    font-weight: 500;
    font-size: 2em;
    font-family: $jp;
    text-shadow: 0px 0px 10px #ffffff;
    width: 100%;
    text-align: center;
    letter-spacing: 0;
    margin: 0;
    @include media(md) {
      font-size: 2.5em;
    }
  }
}

// h2
h2 {
  @include heading-two;
  &.default{
    color: $base-color;
    text-align: center;
    position: relative;
    margin-bottom: 1em;
    padding-bottom: 0.75em;
    font-weight: 500;
    line-height: 1.5;
    &::after{
      content: "";
      background: url( $img-path + 'border_bl.svg' )no-repeat;
      display: block;
      width: 100%;
      height: 4px;
      background-size: auto 100%;
      background-position: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    span{
      font-size: 0.6em;
      display: block;
    }
  }
  &.blue{
    color: $base-color;
    font-weight: 400;
    font-size: 2em;
  }
}

// h3
h3 {
  @include heading-three;
  &.default{
    font-weight: 500;
    color: #333;
  }
  &.default-upper{
    font-weight: 500;
    color: #333;
    margin-top: 0;
  }
  &.blue{
    font-weight: 500;
    color: $base-color;
  }
  &.blue-upper{
    font-weight: 500;
    color: $base-color;
    margin-top: 0;
  }
  &.border{
    font-weight: 500;
    color: #333;
    & span{
      border-bottom: solid 2px rgba(#133a88,0.8);
    }
  }
  &.border-upper{
    font-weight: 500;
    color: #333;
    margin-top: 0;
    span{
      border-bottom: solid 2px rgba(#133a88,0.8);
    }
  }
  &.bl{
    font-weight: 400;
    color: #333;
    border-left: solid 2px rgba($base-color,1);
    padding-left: 0.5em;
  }
  &.bl-upper{
    font-weight: 400;
    color: #333;
    margin-top: 0;
    border-left: solid 2px rgba($base-color,1);
    padding-left: 0.5em;
  }
  &.bg{
    font-weight: 500;
    color: #333;
    border-left: solid 4px #133a88;
    background: #f5f4ed;
    padding: 0.5em 1em;
  }
  &.ul{
    font-weight: 400;
    border-bottom: solid 2px rgba($base-color,1);
  }
}

// h4
h4 {
  @include heading-four;
  &.default{
    font-weight: 500;
    color: $base-color;
  }
  &.default-upper{
    font-weight: 500;
    margin-top: 0;
    color: $base-color;
  }
}

// h5
h5 {
  @include heading-five;
  &.default{
    font-weight: 500;
  }
  &.default-upper{
    font-weight: 500;
    margin-top: 0;
  }
}

// h6
h6 {
  @include heading-six;
  &.default{
    font-weight: 500;
  }
  &.default-upper{
    font-weight: 500;
    margin-top: 0;
  }
}

// figcaption
figcaption {
  font-size: 1.2rem;
  @include media(lg) {
    font-size: 1.4rem;
  }
}

// code
pre {
  background-color: #fff;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code,
pre {
  font-family: 'Source Code Pro', monospace;
  font-size: .9375em;
}

pre {
  padding: 1em 0;
}

code {
  background-color: #f8f8f8;
  color: #f43254;
  font-weight: bold;
  padding: .4em;
  margin: 0 0.4em;
  vertical-align: initial;
}

pre code {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  vertical-align: inherit;
}