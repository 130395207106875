@charset "UTF-8";

// 単位を em に変換
@function convert-em($width) {
  @return '#{ $width / 16 }em';
}

// メディアクエリの書き出し
@mixin media($data) {
  $key: map-get($breakpoints, $data);
  @media screen and (min-width: #{convert-em($key)}) {
    @content;
  }
}
