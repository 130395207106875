@charset "UTF-8";

// ---------------------------------------------------------------- 全体の大枠
.general-wrapper {
  position: relative;
  width: 100%;
}

.general-layout {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  min-height: 100vh;
  header, footer {
    flex: none;
  }
  main {
    width: 100%;
    flex: 1 0 auto;
    min-height: 0%; // IE11用
  }
}

// ---------------------------------------------------------------- wrapper
.wrapper {
  width: 100%;
  min-width: 320px;
}

// ---------------------------------------------------------------- container
.container {
  background: $background-container;
  padding: 0 container-padding(sm, 1);
  margin: 0 auto;
  width: 100%;
  @include media(md) {
    //padding: 0 container-padding(md, 1);
    //@include container-fluid-flag();
  }
  @include media(lg) {
    padding: 0 container-padding(lg, 1);
    @include container-fluid-flag(lg);
  }
}
// IE11 用の hack
_:-ms-lang(x), .container { flex: 0; }
_:-ms-lang(x), .row-container { flex: 0; }

// ---------------------------------------------------------------- row-container
.row-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @extend .container;

  .main-column {
    order: 0;
    width: 100%;
    @include media(md) {
      flex: 1 0 1%;
    }
    @include media(lg) {
      order: 1;
    }
  }

  .right-column {
    margin-top: unit-convert($main-top-margin, rem);
    order: 2;
    width: 100%;
    @include media(md) {
      margin-top: unit-convert(($main-top-margin * ( map-get( $base-font-size, md ) / map-get( $base-font-size, sm ) )), rem);
    }
    @include media(lg) {
      margin-top: 0;
      margin-left: unit-convert($main-side-margin, rem);
      width: unit-convert($width-right-column, rem);
    }
  }

  .left-column {
    margin-top: unit-convert($main-top-margin, rem);
    order: 1;
    width: 100%;
    @include media(md) {
      margin-top: unit-convert(($main-top-margin * ( map-get( $base-font-size, md ) / map-get( $base-font-size, sm ) )), rem);
    }
    @include media(lg) {
      margin-top: 0;
      margin-right: unit-convert($main-side-margin, rem);
      order: 0;
      width: unit-convert($width-left-column, rem);
    }
  }
}

// ---------------------------------------------------------------- section-inner
.section-inner {
  padding: map($section-margin, sm) 0;
  @include media(md) {
    padding: map($section-margin, md) 0;
  }
  @include media(lg) {
    padding: map($section-margin, lg) 0;
  }
}
.section-inner-upper {
  padding-bottom: map($section-margin, sm);
  @include media(md) {
    padding-bottom: map($section-margin, md);
  }
  @include media(lg) {
    padding-bottom: map($section-margin, lg);
  }
}
.section-inner-lower {
  padding-top: map($section-margin, sm);
  @include media(md) {
    padding-top: map($section-margin, md);
  }
  @include media(lg) {
    padding-top: map($section-margin, lg);
  }
}
